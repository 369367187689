<div class="details-wrapper">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <span class="mat-content">
          <mat-panel-title>
            <span i18n="@@basketPanelTotalShowDetails">
              Show Details
            </span>
            <span class="sr-only"> for {{ detail?.vendorName }} car</span>
          </mat-panel-title>
        </span>
      </mat-expansion-panel-header>
        <ng-container>
          <div class="summary">
            <div *ngIf="detail?.identifiers?.vehType as vehType">
              <strong>{{vehType}}</strong>
            </div>
            <div *ngIf="detail?.car as car">
              <div *ngIf="car?.class">
                <strong i18n="@@itineraryCategory">Category:</strong> {{car?.class}} 
              </div>
              <div *ngIf="car?.type">
                <strong i18n="@@itineraryType">Type:</strong> {{searchParams.getCarTypeForDisplay(car.type)}}
              </div>
            </div>
          </div>
        </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>