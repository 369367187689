<div class="tabs_container_flightDetails" aria-live="assertive">
  <div class="tabsHeader">
    <div
      class="tab_base"
      [ngClass]="{'tab_select': selectedTab === TabType.FareDetails}"
      (click)="selectedTab = TabType.FareDetails"
      (keydown.enter)="selectedTab = TabType.FareDetails"
      tabindex="0"
      role="tab"
      i18n="@@itemDetailsFaredetails"
      aria-level="Fare Details"
      id="flight-details-fare-details-{{flightIndex}}">
      Fare Details
    </div>
    <div
      class="tab_base"
      [ngClass]="{'tab_select': selectedTab === TabType.FlightLegTimes}"
      (keydown.enter)="selectedTab = TabType.FlightLegTimes"
      (click)="selectedTab = TabType.FlightLegTimes"
      tabindex="0"
      role="tab"
      i18n="@@itemDetailsFlightLegTimes"
      aria-level="Show Details"
      id="flight-details-show-details-{{flightIndex}}">
      Show Details
    </div>
  </div>
  <div
    class="pane"
    aria-expanded="Fare Details"
    *ngIf="selectedTab === TabType.FareDetails"
    id="0"
    i18n-Title>
    <div class="ticketScroll">
      <ng-container>
        <app-fares-table 
        (selectedMultiCityFlight)="selectedMultiCityFlight.emit($event);"
        (deselectMultiCityFlight)="deselectMultiCityFlight.emit($event)"
        [selectedItemsId]="selectedItemsId"
        [disableItems]="disableItems"
        [isExchange]="isExchange"
        [isPricingExchange]="isPricingExchange"
        [isMultiCity]="searchParams.chosenSearchType === FlightSearchType.multiCity"
        [isDualFlight]="isDualFlight"
        [isDualReturn]="isDualReturn"
        (addToBasketEvent)="addToBasket($event)" 
        [isTravelfusion]="isTravelfusion"
        [fares]="fares" 
        tabindex="0" 
        [searchParams]="searchParams" 
        [showFaresBlock]="true"></app-fares-table> 
      </ng-container>
    </div>
  </div>
  <div
    class="pane"
    *ngIf="selectedTab === TabType.FlightLegTimes"
    id="1"
    aria-expanded="Show Details">
    <div class="leg_wrapper">
      <div class="leg_container">
        <app-routehappy [flight]="flightDetails"></app-routehappy>
        <app-flight-legs
          [legs]="flightDetail.outboundFlights"
          [flight]="flightDetail"
          [isMultiCity]="searchParams.chosenSearchType === FlightSearchType.multiCity"
          [flightIndex]="flightIndex">
        </app-flight-legs>
        <div *ngIf="flightDetail.inboundFlights.length">
          <app-flight-legs
            [legs]="flightDetail.inboundFlights"
            [flight]="flightDetail"
            [isReturn]="true"
            [isMultiCity]="searchParams.chosenSearchType === FlightSearchType.multiCity"
            [flightIndex]="flightIndex">
          </app-flight-legs>
        </div>
      </div>
    </div>
  </div>
</div>

