import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  FlightJourney,
  HelperRoutines,
  ServiceType,
  EnterpriseSearchService,
  ModalOpenerService,
  UserService
} from '@sabstravtech/obtservices/angular';
import {
  FlightEnterpriseSearchInterface,
  FlightItineraryWithExtensions,
  RuleActionSummaryInterface
} from '@sabstravtech/obtservices/base';
import moment from 'moment';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../../../shared/services/dialog.service';

@Component({
  selector: 'app-multi-city-flights-by-schedule',
  templateUrl: './multi-city-flights-by-schedule.component.html',
  styleUrl: './multi-city-flights-by-schedule.component.scss'
})
export class MultiCityFlightsByScheduleComponent implements OnInit {
  @Input() multiCityByScheduleFlightsList: FlightJourney[] = [];
  @Input() source: string = '';
  @Input() requiresReason: string[] = [];

  @Output() removeAndSearchAgain: EventEmitter<boolean> = new EventEmitter();
  searchParams: FlightEnterpriseSearchInterface;
  ServiceType: typeof ServiceType = ServiceType;
  price: number;
  flightItinerary: FlightItineraryWithExtensions = null;
  loadingPrice = false;
  displayItinerary = false;
  constructor(
    private helpers: HelperRoutines,
    public searchService: EnterpriseSearchService,
    private modalService: ModalOpenerService,
    private userService: UserService,
    private translateService: TranslateService,
    private dialogService: DialogService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.multiCityByScheduleFlightsList) {
      this.multiCityByScheduleFlightsList = this.multiCityByScheduleFlightsList.sort((a, b) => {
        if (a.segmentGroup < b.segmentGroup) {
          return -1;
        }
        if (a.segmentGroup > b.segmentGroup) {
          return 1;
        }
        return 0;
      });
      this.flightItinerary = null;
    }
  }

  ngOnInit() {
    this.searchParams = this.searchService.searches[ServiceType.Flight];
    this.displayItinerary = this.userService.isBasketReplacementUserFavouriteSet();
  }

  getPrice() {
    const { adults, children } = this.searchParams;
    const outboundFlights = this.multiCityByScheduleFlightsList;
    this.loadingPrice = true;
    this.searchService
      .getFlightAtNewClass(
        this.searchParams.getSearchQuery(),
        { outboundFlights, source: this.source },
        adults,
        children
      )
      .subscribe(
        (newResult: {
          flights: FlightItineraryWithExtensions[];
          ruleActionSummary: RuleActionSummaryInterface;
        }) => {
          if (newResult.flights[0]) {
            this.loadingPrice = false;
            this.flightItinerary = newResult.flights[0];
          } else {
            this.modalService
              .open(
                LightningModalTypes.InfoModalComponent,
                { windowClass: 'fare-rules-modal', centered: true },
                {
                  title: this.translateService.instant('Warning'),
                  info: this.translateService.instant('The airlines you have selected do not combine to offer a competitive pricing option for you, please try another option, or call our travel team for more help.'),
                  buttonText: this.translateService.instant('Ok')
                }
              )
              .then(result => {
                if (result) {
                  this.removeAndSearchAgain.emit(true);
                }
              });
          }
        }
      );
  }

  async addToBasket(): Promise<void> {
    console.log('+++ Adding to basket: ', this.flightItinerary, ' +++');
    const flightCopy = <FlightItineraryWithExtensions>this.helpers.clone(this.flightItinerary);
    flightCopy.outboundFlights.forEach(flight => {
      if (!flight.destinationTerminal) {
        flight.destinationTerminal = '';
      }
    });

    flightCopy.requiresReasonKeys = this.requiresReason;

    if (this.showMixedCabin(flightCopy.outboundFlights)) {
      const mixedCabinResult = await this.dialogService.showMixedCabinDialog();
      if (!mixedCabinResult) return;
    }

    await this.searchParams.selectFlightSeatMapCheck(flightCopy);
  }

  showMixedCabin(flights: FlightJourney[]){
    return this.searchParams.showMixedCabin(flights);
  }

  parseDateToMoment(dateString: string): moment.Moment {
    return moment(this.helpers.sanitiseDateTime(dateString));
  }
}

