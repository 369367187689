<ng-template #emailrt let-r="result" let-t="term">
  <div class="item-search-select">
    <span class="item-dest">{{ r.surname }}, {{ r.forename }} {{ r.email }}</span>
  </div>
</ng-template>

<ng-template #companyrt let-r="result" let-t="term">
  <div class="item-search-select">
    <span class="item-dest">{{ r.name }}</span>
  </div>
</ng-template>

<div>
  <label i18n="@@invokeUser_label">Book for</label>
  <section (mouseleave)="tt.close()" class="container">
    <i
      class="ico ico-info-circle"
      i18n-ngbTooltip
      ngbTooltip="Use this option to apply the settings of the person you want to book for so that any configuration settings for Travel Policy, Management Information and Products they are permitted to book will apply to the person you are booking for plus any additional Travellers."
      triggers="manual"
      #tt="ngbTooltip"
      (mouseenter)="tt.open()"
      (focus)="tt.open()"
      (blur)="tt.close()"
      tabindex="0"
      aria-label="Use this option to apply the settings of the person you want to book for so that any configuration settings for Travel Policy, Management Information and Products they are permitted to book will apply to the person you are booking for plus any additional Travellers.">
      <span class="visuallyhidden"
        >Use this option to apply the settings of the person you want to book for so that any
        configuration settings for Travel Policy, Management Information and Products they are
        permitted to book will apply to the person you are booking for plus any additional
        Travellers.</span
      >
    </i>
  </section>
  <div class="flex-row-container" *ngIf="!invokeuser?.id">
    <!-- <input
            *ngIf="userIsAgent"
            class="input-box input-box--border input-box--no-iconed company-input"
            [ngbTypeahead]="searchCompanies"
            [resultTemplate]="companyrt"
            [(ngModel)]="company"
            placeholder="Search for a company"
            [inputFormatter]="companyFormatter"
            i18n-placeholder
            (click)="$event.currentTarget.select()"
            type="text" />
        <i *ngIf="company?.id" aria-label="remove company" class="ico-cancel company-cancel" (click)="company = null"></i> -->
    <input
      class="input-box input-box--border input-box--no-iconed"
      [ngbTypeahead]="searchTravellers"
      [resultTemplate]="emailrt"
      [(ngModel)]="invokeuser"
      placeholder="Enter the email address of the user you want to book for."
      [inputFormatter]="userFormatter"
      i18n-placeholder
      (click)="$event.currentTarget.select()"
      (ngModelChange)="change()"
      type="text" />
  </div>
  <app-invoked-user (reset)="removeBobo()" [searchPage]="true"></app-invoked-user>
</div>
