import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, Subscriber } from 'rxjs';
import { take } from 'rxjs/operators';

export class TranslateCSVLoader implements TranslateLoader {
  httpClient: HttpClient;
  url: string;
  constructor(httpClient: HttpClient, url: string) {
    this.httpClient = httpClient;
    this.url = url;
  }

  getTranslation(lang: string): Observable<string> {
    return new Observable<string>((subscriber: Subscriber<{}>) => {
      this.httpClient
        .get(this.url, { responseType: 'text' })
        .pipe(take(1))
        .subscribe(
          (data: string) => {
            const rows: string[] = data.split('\n');
            if (rows.length > 1) {
              const keys: string[] = rows[0].split(',');
              const index: number = keys.findIndex(
                (item) => item.trim() === lang
              );
              if (index >= 0) {
                rows.shift();
                subscriber.next(
                  rows.reduce((current, row) => {
                    const items: string[] = row.split(',');
                    if (items[0]) {
                      current[items[0].trim()] = (
                        items[index] || items[0]
                      ).trim();
                    }
                    return current;
                  }, {})
                );
              }
            }
          },
          (error) => subscriber.error(error),
          () => subscriber.complete()
        );
    });
  }
}
