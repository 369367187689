import { Pipe, PipeTransform } from '@angular/core';
import {
  BasketStatus
} from '@sabstravtech/obtservices/angular';

@Pipe({
  name: 'basketStatusPipe',
})
export class BasketStatusPipe implements PipeTransform {
  transform(value: string): string {
    if (value === BasketStatus.Disapproved) {
      return "Declined";
    } else {
      return value;
    }
  }
}
