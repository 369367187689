import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbDateStruct, NgbTooltip, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, of, BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError } from 'rxjs/operators';
import {
    EnterpriseSearchService,
    UserService,
    ServiceType,
    RailSearchJourneyType,
    IrlJourneyType,
    IrlTravelCard,
    FerryOrEurotunnel,
    FerryPort,
    User,
    TicketType
} from '@sabstravtech/obtservices/angular';
import {
    FerryEnterpriseSearchInterface,
    Traveller,
    LocationDetails
} from '@sabstravtech/obtservices/base';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';

import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';
import { Helpers } from '../../../vendor/classes/helpers';
// import { SetDefaultService } from '../../obtservices/vendor/services/set-default.service';
// import { UserFavorurite } from '../../obtservices/vendor/enums/user-favourite.enum';

@Component({
    selector: 'app-ferry-search',
    templateUrl: './ferry-search.component.html',
    styleUrls: ['./ferry-search.component.scss']
})
export class FerrySearchComponent extends BaseComponent implements OnInit {
    @ViewChild('instance') instance: NgbTypeahead;
    @ViewChild('ttip') ttip: NgbTooltip;
    focus$ = new Subject<string>();
    focus2$ = new Subject<string>();
    focus3$ = new Subject<string>();
    public input1Moment: any;

    allowOpenReturn = true;
    ServiceType: typeof ServiceType = ServiceType;
    IrlJourneyType: typeof IrlJourneyType = IrlJourneyType;
    ferryOrEurotunnel: typeof FerryOrEurotunnel = FerryOrEurotunnel;
    travellers: Traveller[];

    searchParams: FerryEnterpriseSearchInterface;
    typeaHeadLimit = Number.MAX_SAFE_INTEGER;

    travellingFrom: Observable<any[]> = of([]);
    travellingTo: Observable<any[]> = of([]);
    travellingVia: Observable<any[]> = of([]);
    discountCards: any = [];
    isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    ticketInfoLink = "https://www.leshuttle.com/uk-en/tickets-fares-add-ons";
    public selectedDiscountCards: IrlTravelCard[] = [];
    private changeBoboSubscription: Subscription;

    constructor(
        private userService: UserService,
        public deviceDetector: DeviceDetector,
        public searchService: EnterpriseSearchService,
        private titleCasePipe: TitleCasePipe,
        public title: Title,
        public translateService: TranslateService
    ) {
        super(title, translateService);
    }

    ngOnInit(): void {
        this.allowOpenReturn = !this.userService.isUserFavoriteSet(
            LightningUserFavorurite.no_open_returns
        );
        this.searchParams = this.searchService.searches[ServiceType.Ferry];
        this.searchService.travellerInformation.subscribe((travellers: Traveller[]) => {
            this.travellers = travellers;
        });
        this.loadDefaultUser();
    }

    private async loadDefaultUser(): Promise<void> {
        let currentUser: Traveller | User;
     
        this.changeBoboSubscription = this.userService.changeBobo.subscribe(async (traveller: Traveller) => {
            if (traveller) {
                currentUser = traveller;
            } else {
                currentUser = await this.getCurrentUser();
            }
            if (currentUser) {
                this.searchParams.dob = currentUser.dob ? Helpers.dateToNgDate(new Date(currentUser.dob)) : null;
                const primaryPhoneNumber = (currentUser as User).phoneNumbers?.find(phone => phone.userPrimary || phone.officePrimary);
                this.searchParams.phoneNumber = primaryPhoneNumber 
                    ? `${primaryPhoneNumber.countryCode}${primaryPhoneNumber.areaCode}${primaryPhoneNumber.number}`
                    : '';
            }
        });
    }

    async getCurrentUser(): Promise<Traveller | User> {
        return this.userService.bobo ?? await firstValueFrom(this.userService.getUser());
    }

    formatter2 = (x: { name: string }) => x.name;

    ferryLocations = (text$: Observable<string>) => this.locationTypeahead(text$);

    private locationTypeahead(text$: Observable<string>): Observable<LocationDetails[]> {
        return text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap(
                (term: string): Observable<LocationDetails[]> =>
                    term.length <= 2
                        ? of([])
                        : this.searchService.getFerryPorts(term).pipe(
                              map((res: FerryPort[]) => {
                                  console.log(res);
                                  return res.map((ferryPort: FerryPort) => {
                                    return ferryPort.ferryPortName;
                                  }).slice(0, this.typeaHeadLimit);
                              }),
                              catchError(() => of([]))
                          )
            )
        );
    }

    formatter = (ferryPortName): string => ferryPortName;

    ensureElementIsScrolledTo(event) {
        try {
            const typeAheadList = event.target.nextElementSibling;
            const activeButton = typeAheadList.getElementsByClassName('active')[0];
            if (
                activeButton.offsetTop + activeButton.clientHeight >
                typeAheadList.clientHeight + typeAheadList.scrollTop
            ) {
                typeAheadList.scrollTop =
                    activeButton.offsetTop + activeButton.clientHeight - typeAheadList.clientHeight;
            } else if (activeButton.offsetTop < typeAheadList.scrollTop) {
                typeAheadList.scrollTop = activeButton.offsetTop;
            }
        } catch (e) {
            // tslint:disable-next-line: quotemark
            console.log("Couldn't find elements to scroll");
        }
    }

    isValidTime(favouriteTime: string): boolean {
        return !!favouriteTime.match(/^(\d{2}):(\d{2})$/);
    }

    cardComparator(a: IrlTravelCard, b: IrlTravelCard): boolean {
        return a?.name === b?.name;
    }

    resetAffectedFields(): void {
        if (this.searchParams.ferryOrEurotunnel === FerryOrEurotunnel.Ferry) {
            this.searchParams.fromLocation = null;
            this.searchParams.toLocation = null;
            this.searchParams.height = null;
            this.searchParams.length = null;
            this.searchParams.preferredTicketType = TicketType.None;
            this.searchParams.vehicleType = null;
        }
        else if (this.searchParams.ferryOrEurotunnel === FerryOrEurotunnel.Eurotunnel) {
            this.searchParams.fromLocation = null;
            this.searchParams.toLocation = null;
            this.searchParams.height = "N/A";
            this.searchParams.length = "N/A";
            this.searchParams.preferredTicketType =  TicketType.None;
            this.searchParams.vehicleType = null;
        }
    }

    ngOnDestroy(): void {
        if (this.changeBoboSubscription) {
            this.changeBoboSubscription.unsubscribe();
        }
    }
}
