<div class="modal-header">
  <h3 class="modal-title" i18n="@@UpsellFare">Upsell Fare</h3>
  <button type="button" class="close" (click)="modal.close()" aria-label="Close">
    <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
  </button>
</div>
<div class="modal-body">
  <p i18n="@@Wehavesomealternative">We have some alternative suggestions that could better meet your needs. Why not take a quick look below?</p>
  <div class="fares-wrapper">
    <app-fare-section
      [fare]="selectedFare"
      [title]="'Your selection'"
      [isDualFlight]="isDualFlight"
      [isDualFlightReturn]="isDualFlightReturn"
      (selectFare)="addToBasket($event)">
    </app-fare-section>

    <div *ngFor="let fare of upsellFares">
      <app-fare-section
        [fare]="fare"
        [title]="'Upsell Fare'"
        (selectFare)="addToBasket($event)"
        [isDualFlight]="isDualFlight"
        [isDualFlightReturn]="isDualFlightReturn"
        [isUpsell]="true">
      </app-fare-section>
    </div>
  </div>

  <div class="fare-header-wrapper">
    <div class="fare-header">
      <ul aria-hidden="true">
        <li>
          <span i18n="@@UpsellFareRules">Fare Rules</span>
        </li>
        <li>
          <fa-icon [icon]="faSuitcaseRolling" aria-hidden="true"></fa-icon>
          <span i18n="@@UpsellCarryOnBaggage">Carry-on Baggage</span>
        </li>
        <li>
          <fa-icon [icon]="faSuitcase" aria-hidden="true"></fa-icon>
          <span i18n="@@UpsellCheckedBaggage">Checked Baggage</span>
        </li>
        <li>
          <fa-icon [icon]="faCalendarDays" aria-hidden="true"></fa-icon>
          <span i18n="@@UpsellChanges">Changes</span>
        </li>
        <li>
          <fa-icon [icon]="faCoins" aria-hidden="true"></fa-icon>
          <span i18n="@@UpsellRefunds">Refunds</span>
        </li>
        <li>
          <i class="ico-plain-seat" aria-hidden="true"></i>
          <span i18n="@@UpsellSeat">Seat</span>
        </li>  
        <li>
          <fa-icon [icon]="faUtensils" aria-hidden="true"></fa-icon>
          <span i18n="@@UpsellMeal">Meal</span>
        </li>
        <li>
          <fa-icon [icon]="faWifi" aria-hidden="true"></fa-icon>
          <span i18n="@@UpsellWifi">Wifi</span>
        </li>
      </ul>
    </div> 
  </div>
</div>