<div id="breakdown-dialog">
  <div class="modal-header">
    <h3 class="modal-title" i18n="@@flight-extras">Flight Extras</h3>
    <button
      type="button"
      class="light-icon-button close"
      aria-label="Close"
      (click)="activeModal.close(false)"
      ngbAutoFocus>
      <i class="ico-cancel AA-standard-grey" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="travellers.length">
      <!-- IF TWO and MORE Travellers -->
      <div *ngIf="travellers.length > 1; else showTravellers">
        <nav
          ngbNav
          #nav="ngbNav"
          (ngbNavChange)="activeTab = $event.nextId"
          [destroyOnHide]="false">
          <ng-container
            ngbNavItem
            *ngFor="let traveller of notProcessedTravellers; let index = index">
            <button ngbNavLink type="button">
              {{ traveller.surname }} {{ traveller.forename }}
            </button>
            <span
              class="chosen_seat"
              >{{ travellerSeat?.[index]?.[activeFlightID[traveller.id]] }}</span
            >
            <ng-template ngbNavContent>
              <div class="traveller-extras-container">
                <app-flight-seat-map-tab
                  [travellerId]="traveller.id"
                  [travellerDob]="traveller.dob"
                  [travellerIndex]="index"
                  [selectedSeatWithId]="selectedSeatWithId"
                  [flight]="flight"
                  [isGuest]="traveller.guest"
                  [supplier]="flight.source"
                  [userName]="traveller.forename + ' ' + traveller.surname"
                  (seat)="getSeat($event, index)"
                  (loyaltyCode)="getLoyalty($event, index, traveller.id)"
                  (flightExtras)="flightExtras($event, traveller.id)"
                  (processed)="processedTab(traveller.id)"
                  (onNotValid)="setFlightNotValid($event)"
                  (loyaltyCodesChange)="loyaltyChange($event)"
                  (activeFlightID)="setActiveFlightID($event)">
                </app-flight-seat-map-tab>
              </div>
            </ng-template>
          </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="seatmap"></div>
      </div>

      <!-- IF ONE Traveller-->
      <ng-template #showTravellers>
        <div *ngFor="let traveller of travellers; let index = index">
          <h4>
            {{ traveller.surname }} {{ traveller.forename }}
            <span
              class="chosen_seat"
              >{{ travellerSeat?.[index]?.[activeFlightID[traveller.id]] }}</span
            >
          </h4>

          <div class="traveller-extras-container">
            <app-flight-seat-map-tab
              [travellerId]="traveller.id"
              [travellerDob]="traveller.dob"
              [flight]="flight"
              [supplier]="flight.source"
              [selectedSeatWithId]="selectedSeatWithId"
              [userName]="traveller.forename + ' ' + traveller.surname"
              (seat)="getSeat($event)"
              (loyaltyCode)="getLoyalty($event, 0, traveller.id)"
              (flightExtras)="flightExtras($event, traveller.id)"
              (onNotValid)="setFlightNotValid($event)"
              (loyaltyCodesChange)="loyaltyChange($event)"
              (activeFlightID)="setActiveFlightID($event)"
              [isGuest]="traveller.guest"></app-flight-seat-map-tab>
          </div>
        </div>
      </ng-template>
    </div>
    <div *ngIf="!travellers.length">
      <i class="button_spinner"></i>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      id="seat-map-next-button"
      class="small-button"
      *ngIf="!updatingSeats"
      (click)="addToBasket()"
      [disabled]="notValid">
      <span *ngIf="!changesMade.length" i18n="@@flight-extras-skip" id="seat-map-next-button-skip">
        Skip
      </span>
      <span
        *ngIf="changesMade.length && !displayItinerary"
        i18n="@@flight-extras-basket"
        id="seat-map-next-button-add">
        Add to Basket
      </span>
      <span
        *ngIf="changesMade.length && displayItinerary"
        i18n="@@flight-extras-itinerary"
        id="seat-map-next-button-add">
        Add to Itinerary
      </span>
    </button>
    <button *ngIf="updatingSeats" class="small-button" (click)="updateSeatMap()">
      <span i18n="@@flight-seat-update" id="seat-map-next-update"> Update Seats </span>
    </button>
  </div>
</div>
