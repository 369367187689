import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WithSubscriptionComponent, UserService } from '@sabstravtech/obtservices/angular';
@Component({
  selector: 'app-invoked-user',
  templateUrl: './invoked-user.component.html',
  styleUrls: ['./invoked-user.component.scss']
})
export class InvokedUserComponent extends WithSubscriptionComponent {

  @Output() reset = new EventEmitter<void>();
  @Input() searchPage: boolean = false;
  constructor(public service: UserService) { super(); }

  clearBobo() {
    this.service.setBobo(null);
    this.reset.emit();
  }
}
