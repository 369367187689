<div class="eurostar-list" *ngIf="results && results.length">
  <div class="eurostar-list_header">
    <span i18n="@@from">From</span> {{ from.destination }} <span i18n="@@to">to</span>
    {{ to.destination }}, {{ getDate() }}
  </div>
  <div
    class="eurostar-list_item half-container flex-row-container--tb-margin"
    *ngFor="let item of results">
    <div class="half-block flex-row-container logo_time">
      <div class="logo-container">
        <img [src]="eurostarLogo" class="eurostar-list_item-logo" alt="Eurostar Logo" />
      </div>
      <div class="eurostar-list_schedule flex-row-container">
        <div class="eurostar-list_time">
          {{ item.timeDeparture }}
          <section *ngIf="item.timeDepartureOffset" (mouseleave)="ttOff1.close()">
            <div
              class="offset_notification"
              ngbTooltip="{{ item.departureCountryCode }} time is +{{
                item.timeDepartureOffset
              }} from UK time"
              placement="right"
              tooltipClass="custom-tooltip"
              triggers="manual"
              #ttOff1="ngbTooltip"
              (mouseenter)="ttOff1.open()"
              (focus)="ttOff1.open()"
              (blur)="ttOff1.close()"
              tabindex="0">
              +{{ item.timeDepartureOffset }}
            </div>
          </section>
        </div>
        <div class="eurostar-list_duration">{{ item.duration }}</div>
        <div class="eurostar-list_time">
          {{ item.timeArrival }}
          <section *ngIf="item.timeArrivalOffset" (mouseleave)="ttOff2.close()">
            <div
              class="offset_notification"
              ngbTooltip="{{ item.arrivalCountryCode }} time is +{{
                item.timeArrivalOffset
              }} from UK time"
              placement="right"
              tooltipClass="custom-tooltip"
              tooltipClass="custom-tooltip"
              triggers="manual"
              #ttOff2="ngbTooltip"
              (mouseenter)="ttOff2.open()"
              (focus)="ttOff2.open()"
              (blur)="ttOff2.close()"
              tabindex="0">
              +{{ item.timeArrivalOffset }}
            </div>
          </section>
        </div>
        <div class="eurostar-list_legs">
          <span class="eurostar-list_leg" i18n="@@eurostarListDirect">Direct</span>
        </div>
      </div>
      <app-preferred-icons
        [tmcPreferred]="
          item.standardFare.tmcPreferred ||
          item.standardPremierFare.tmcPreferred ||
          item.businessPremierFare.tmcPreferred
        "
        [tmcPreferredLogoUrl]="
          item.standardFare.tmcPreferredLogoUrl ||
          item.standardPremierFare.tmcPreferredLogoUrl ||
          item.businessPremierFare.tmcPreferredLogoUrl
        "
        [tmcPreferredMessage]="
          item.standardFare.tmcPreferredMessage ||
          item.standardPremierFare.tmcPreferredMessage ||
          item.businessPremierFare.tmcPreferredMessage
        "
        [clientPreferred]="
          item.standardFare.companyPreferred ||
          item.standardPremierFare.companyPreferred ||
          item.businessPremierFare.companyPreferred ||
          item.standardFare.officePreferred ||
          item.standardPremierFare.officePreferred ||
          item.businessPremierFare.officePreferred
        "
        [companyPreferredMessage]="
          item.standardFare.companyPreferredMessage ||
          item.standardPremierFare.companyPreferredMessage ||
          item.businessPremierFare.companyPreferredMessage
        "
        [officePreferredMessage]="
          item.standardFare.officePreferredMessage ||
          item.standardPremierFare.officePreferredMessage ||
          item.businessPremierFare.officePreferredMessage
        ">
      </app-preferred-icons>
    </div>
    <!--  -->
    <div class="half-block flex-row-container">
      <button
        (click)="toggleFare(item, item.standardFare)"
        aria-labelledby=""
        [disabled]="
          (item.standardFare.isDisabled ||
            searchParams.selectedFareClass === EuroClass.Business ||
            item.standardFare.unavailable ||
            (this.resType === ResultType.Inbound && !this.searchParams.selectedEurostarFare)) &&
          !canOverride
        "
        [ngClass]="{ 'eurostar-list_item-fare--selected': fareIsSelected(item.standardFare) }"
        class="eurostar-list_item-fare"
        id="{{ !!item.standardFare.price ? 'standard-ticket' : 'no-ticket' }}"
        *ngIf="showClasses.standard">
        <div class="flex-row-container">
          <span class="sr-only" i18n="@@eurostarListStandardFare">Eurostar Standard fare</span>
          <span
            id="{{ !!item.standardFare.price ? 'price' : 'no-price' }}"
            *ngIf="
              resType === ResultType.Outbound ||
              searchParams.selectedFareClass !== EuroClass.Premium ||
              newFareHasLoaded(item.standardFare)
            "
            >{{ item.standardFare.price | currency : item.standardFare.currency }}</span
          >
          <span
            class="load_new_fare"
            i18n="@@eurostarLoadNewFee"
            *ngIf="
              resType === ResultType.Inbound &&
              searchParams.selectedFareClass === EuroClass.Premium &&
              !newFareHasLoaded(item.standardFare) &&
              item.standardFare !== loadingFare
            "
            >Load new fare...<span class="sr-only"
              >Old fare: {{ item.standardFare.price | currency : item.standardFare.currency }}</span
            ></span
          >
          <mat-spinner
            *ngIf="item.standardFare === loadingFare"
            [diameter]="20"
            [color]="'black'"></mat-spinner>
          <app-warning-tags
            *ngIf="item.standardFare.price"
            [ticket]="item.standardFare"
            [isUnavailableOnly]="item.standardFare.unavailable"></app-warning-tags>
        </div>
        <span *ngIf="item.standardFare.price && item.co2PerPassenger" class="co2-display"
          >{{ item.co2PerPassenger }}kg CO<sub>2</sub> pp</span
        >
      </button>
      <button
        (click)="toggleFare(item, item.standardPremierFare)"
        [disabled]="
          (item.standardPremierFare.isDisabled ||
            searchParams.selectedFareClass === EuroClass.Business ||
            item.standardPremierFare.unavailable ||
            (this.resType === ResultType.Inbound && !this.searchParams.selectedEurostarFare) ||
            (this.resType === ResultType.Inbound && !this.searchParams.selectedEurostarFare)) &&
          !canOverride
        "
        class="eurostar-list_item-fare"
        [ngClass]="{
          'eurostar-list_item-fare--selected': fareIsSelected(item.standardPremierFare)
        }"
        id="{{ !!item.standardPremierFare.price ? 'standard-premier-ticket' : 'no-ticket' }}"
        *ngIf="showClasses.standardPremier">
        <div class="flex-row-container">
          <span class="sr-only" i18n="@@eurostarListStandardPremier">Eurostar plus fare</span>
          <span
            id="{{ !!item.standardPremierFare.price ? 'price' : 'no-price' }}"
            *ngIf="
              resType === ResultType.Outbound ||
              searchParams.selectedFareClass !== EuroClass.EconomyFlexi ||
              newFareHasLoaded(item.standardPremierFare)
            "
            >{{ item.standardPremierFare.price | currency : item.standardFare.currency }}</span
          >
          <span
            class="load_new_fare"
            i18n="@@eurostarLoadNewFee"
            *ngIf="
              resType === ResultType.Inbound &&
              searchParams.selectedFareClass === EuroClass.EconomyFlexi &&
              !newFareHasLoaded(item.standardPremierFare) &&
              item.standardPremierFare !== loadingFare
            "
            >Load new fare...<span class="sr-only"
              >Old fare:
              {{ item.standardPremierFare.price | currency : item.standardFare.currency }}</span
            ></span
          >
          <mat-spinner
            *ngIf="item.standardPremierFare === loadingFare"
            [diameter]="20"
            [color]="'black'"></mat-spinner>
          <app-warning-tags
            *ngIf="item.standardPremierFare.price"
            [ticket]="item.standardPremierFare"
            [isUnavailableOnly]="item.standardPremierFare.unavailable"></app-warning-tags>
        </div>
        <span *ngIf="item.standardPremierFare.price && item.co2PerPassenger" class="co2-display"
          >{{ item.co2PerPassenger }}kg CO<sub>2</sub> pp</span
        >
        <!-- <section (mouseleave)="tt3.close()"
                 *ngIf="canShowCantBook(item.standardPremierFare)"
                 class="container">
          <img
             src="{{resultItemType.minus}}"
             class='error_icon'
             ngbTooltip="{{item.standardPremierFare.fare.CannotBookReason}}"
             attr.aria-label="{{item.standardPremierFare.fare.CannotBookReason}}"
             container="body"
             placement="top"
             triggers="manual"
             #tt3="ngbTooltip"
             (mouseenter)="tt3.open()"
             (focus)="tt3.open()"
             (blur)="tt3.close()"
             tabindex="0"
            ><span
                  class="visuallyhidden">{{item.standardPremierFare.fare.CannotBookReason}}</span>
        </section>
        <section (mouseleave)="tt4.close()"
                 *ngIf="canShowRequireReason(item.standardPremierFare)"
                 class="container">
          <img
             src="{{resultItemType.exclamation}}"
             class='error_icon'
             ngbTooltip="{{item.standardPremierFare.fare.ReasonMessage | translate}}"
             attr.aria-label="{{item.standardPremierFare.fare.ReasonMessage | translate}}"
             container="body"
             placement="top"
             triggers="manual"
             #tt4="ngbTooltip"
             (mouseenter)="tt4.open()"
             (focus)="tt4.open()"
             (blur)="tt4.close()"
             tabindex="0"
             [class.can-book-overridden]="item.standardPremierFare.fare.CanBookOverridden"
             ><span
                  class="visuallyhidden">{{item.standardPremierFare.fare.ReasonMessage | translate}}</span>
        </section>
        <section (mouseleave)="tt3.close()"
        *ngIf="!canShowCantBook(item.standardPremierFare) && !canShowRequireReason(item.standardPremierFare)"
        class="container">
          <img
              src="{{resultItemType.check}}"
              class='error_icon'
              tabindex="0"
            >
          </section> -->
      </button>
      <button
        (click)="toggleFare(item, item.businessPremierFare)"
        [disabled]="
          (item.businessPremierFare.isDisabled ||
            searchParams.selectedFareClass === EuroClass.EconomyRestricted ||
            searchParams.selectedFareClass === EuroClass.Premium ||
            searchParams.selectedFareClass === EuroClass.EconomyNoBags ||
            searchParams.selectedFareClass === EuroClass.EconomyFlexi ||
            item.businessPremierFare.unavailable ||
            (this.resType === ResultType.Inbound && !this.searchParams.selectedEurostarFare)) &&
          !canOverride
        "
        class="eurostar-list_item-fare"
        id="{{ !!item.businessPremierFare.price ? 'business-premier-ticket' : 'no-ticket' }}"
        [ngClass]="{
          'eurostar-list_item-fare--selected': fareIsSelected(item.businessPremierFare)
        }"
        *ngIf="showClasses.businessPremier">
        <div class="flex-row-container">
          <span
            class="sr-only"
            i18n="@@eurostarListBusinessPremier"
            id="{{ !!item.businessPremierFare.price ? 'price' : 'no-price' }}"
            >Eurostar Premier fare</span
          >
          {{ item.businessPremierFare.price | currency : item.standardFare.currency }}
          <app-warning-tags
            *ngIf="item.businessPremierFare.price"
            [ticket]="item.businessPremierFare"
            [isUnavailableOnly]="item.businessPremierFare.unavailable"></app-warning-tags>
        </div>
        <span *ngIf="item.businessPremierFare.price && item.co2PerPassenger" class="co2-display"
          >{{ item.co2PerPassenger }}kg CO<sub>2</sub> pp</span
        >
        <!-- <section (mouseleave)="tt5.close()"
                 *ngIf="canShowCantBook(item.businessPremierFare)"
                 class="container">
          <img
            src="{{resultItemType.minus}}"
            class='error_icon'
            ngbTooltip="{{item.businessPremierFare.fare.CannotBookReason}}"
             attr.aria-label="{{item.businessPremierFare.fare.CannotBookReason}}"
             container="body"
             placement="top"
             triggers="manual"
             #tt5="ngbTooltip"
             (mouseenter)="tt5.open()"
             (focus)="tt5.open()"
             (blur)="tt5.close()"
             tabindex="0"
             ><span
                  class="visuallyhidden">{{item.businessPremierFare.fare.CannotBookReason}}</span>
        </section>
        <section (mouseleave)="tt6.close()"
                 *ngIf="canShowRequireReason(item.businessPremierFare)"
                 class="container">
          <img
          src="{{resultItemType.exclamation}}"
          class='error_icon'
          ngbTooltip="{{item.businessPremierFare.fare.ReasonMessage | translate}}"
             attr.aria-label="{{item.businessPremierFare.fare.ReasonMessage | translate}}"
             container="body"
             placement="top"
             triggers="manual"
             #tt6="ngbTooltip"
             (mouseenter)="tt6.open()"
             (focus)="tt6.open()"
             (blur)="tt6.close()"
             tabindex="0"
             [class.can-book-overridden]="item.businessPremierFare.fare.CanBookOverridden"
             ><span
                  class="visuallyhidden">{{item.businessPremierFare.fare.ReasonMessage | translate}}</span>
        </section>
        <section (mouseleave)="tt3.close()"
        *ngIf="!canShowCantBook(item.businessPremierFare) && !canShowRequireReason(item.businessPremierFare)"
        class="container">
          <img
              src="{{resultItemType.check}}"
              class='error_icon'
              tabindex="0"
            >
          </section>

        <span class="neetfees"
              *ngIf="checkNettFees(item.businessPremierFare)">
          <span class="background-layer">
            <img src="{{preferredLogoUrl}}"
                 alt="">
          </span>
        </span> -->
      </button>
    </div>
  </div>
</div>
