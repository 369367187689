import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ModalOpenerService
} from '@sabstravtech/obtservices/angular';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private modalService: ModalOpenerService, private translateService: TranslateService) {}

  async showMixedCabinDialog(): Promise<boolean> {
    const result = await this.modalService.open(
      LightningModalTypes.ModalConfirmComponent,
      { centered: true },
      {
        message_header: this.translateService.instant('Warning!'),
        message: this.translateService.instant(
          'The cabin class is not the same throughout the whole of the journey, would you like to select this flight option anyway?'
        ),
        btnCancelText: this.translateService.instant('No'),
        btnOkText: this.translateService.instant('Yes')
      }
    );
    return !!result;
  }

  async showDifferentClassDialog(): Promise<boolean> {
    const result = await this.modalService.open(
      LightningModalTypes.ModalConfirmComponent,
      { windowClass: 'fare-rules-modal', centered: true },
      {
        message_header: this.translateService.instant('Warning!'),
        message:
          this.translateService.instant('Please note you have selected a different class for the outbound and inbound flight. To continue with your current selection click OK, to select an alternative please select Cancel.'),
        btnOkText: this.translateService.instant('Ok'),
        btnCancelText: this.translateService.instant('Cancel')
      }
    );
    return !!result;
  }
}
