<div *ngIf="service.bobo?.id">
  <span i18n="@@inv-invokeduser">Booking For </span>
  <span>{{ service.bobo.email }}</span>
  <button
    *ngIf="searchPage"
    i18n="@@inv-cancel"
    (click)="clearBobo()"
    id="invoked-user-button-cancel">
    Cancel
  </button>
</div>
