export enum AncillaryStatus {
  Included = 'Included',
  Chargeable = 'Chargeable',
  Excluded = 'Excluded',
  Unknown = 'Unknown'
}

export enum AncillaryType{
  HandBaggage = 'Hand Baggage',
  HoldBaggage = 'Hold Baggage',
  Seat = 'Seat',
  Refund = 'Refund',
  Changes = 'Changes',
  Meal = 'Meal'
};

export enum AncillaryTicket {
  RefundableTicket = 'Refundable Ticket',
  ChangeableTicket = 'Changeable Ticket'
}