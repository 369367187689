<ng-template #loader>
  <div class="recent_searches_no_recent">
    <i class="button_spinner light-center-absolute"></i>
  </div>
</ng-template>
<ng-template #loaderSingle>
  <i class="button_spinner light-center-absolute"></i>
</ng-template>
<section>
  <div class="recent_searches_content background-40">
    <ng-container *ngIf="recentSearches; else loader">
      <div class="recent_searches_recent" *ngIf="recentSearches.length">
        <ul>
          <li *ngFor="let item of recentSearches; let i = index" class="recent-search-item" [@fadeInOut]>
            <ng-container *ngIf="item; else loader">
              <!-- Flight -->
              <div
                class="storageItem"
                *ngIf="item.serviceType === serviceType.Flight"
                [@fadeInOut]
                (click)="performRecentSearch(item)">
                <div class="icons">
                  <i class="ico-flight input_icon" aria-hidden="true"></i>
                </div>
                <div class="details">
                  <p class="recent-searches__ptext recent-searches__ptext--small">
                    {{
                      item.searchArgs?.multiDestination?.length
                        ? ('Multi-City' | translate)&nbsp;
                        : item.searchArgs.returnTrip
                        ? ('Return for' | translate)&nbsp;
                        : ('One-way for' | translate)&nbsp;
                    }}
                    {{ item.searchArgs.passengers | checkPassengerAmount: ('adult' | translate) }}
                    {{('in class' | translate)}}&nbsp;{{ item.searchArgs.outboundCabinClass ? item.searchArgs.outboundCabinClass[0] : ('Unknown'| translate) }}
                    <ng-container *ngIf="item.searchArgs.returnTrip"
                      >,
                      <div *ngIf="item.searchArgs.returnCabinClass">
                        {{ item.searchArgs.returnCabinClass[0] }}
                      </div>
                    </ng-container>
                  </p>
                  <ng-container *ngIf="!item.searchArgs?.multiDestination?.length">
                    <span class="Title" tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}">
                      <!-- keeping the old way as an option so that older items don't error, remove the async part in s55 -->
                      {{
                        item.searchArgs.originAirportFull
                          ? item.searchArgs.originAirportFull +
                            ' (' +
                            item.searchArgs.originAirport +
                            ')'
                          : (item.searchArgs.originAirport | airportIata | async)
                      }}
                      -
                      {{
                        item.searchArgs.destinationAirportFull
                          ? item.searchArgs.destinationAirportFull +
                            ' (' +
                            item.searchArgs.destinationAirport +
                            ')'
                          : (item.searchArgs.destinationAirport | airportIata | async)
                      }}
                      <br />
                    </span>
                    <span class="Date">
                      {{ item.searchArgs.outboundDate | sabsDate: 1 }}
                      {{
                        item.searchArgs.returnDate
                          ? ' - ' + (item.searchArgs.returnDate | sabsDate: 1)
                          : ''
                      }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="item.searchArgs?.multiDestination?.length">
                    <span class="Title" tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}">
                      {{ item.searchArgs.multiDestination[0].orig | airportIata | async }} -
                      {{
                        item.searchArgs.multiDestination[
                          item.searchArgs.multiDestination.length - 1
                        ].dest
                          | airportIata
                          | async
                      }}
                      <br />
                    </span>
                    <span class="Date">
                      {{ item.searchArgs.multiDestination[0].date | sabsDate: 1 }}
                    </span>
                  </ng-container>
                  <br />
                  <br />
                  <app-recent-search-travellers
                    [travellers]="item.travellers"></app-recent-search-travellers>
                </div>
                <div class="right" (click)="removeRecentSearchItem(item, $event)" (keydown.enter)="removeRecentSearchItem(item, $event)" tabindex="0" role="button" attr.aria-labelledby="remove_recent_search_hint recent_search_{{i}}">
                  <i class="ico-cancel recentSearch_remove" aria-hidden="true"></i>
                </div>
              </div>
              <!-- Rail -->
              <div
                class="storageItem"
                *ngIf="item.serviceType === serviceType.Rail"
                [@fadeInOut]
                (click)="performRecentSearch(item)">
                <div class="icons">
                  <i class="ico-rail input_icon"></i>
                </div>
                <div class="details">
                  <span class="Title" *ngIf="!item.searchArgs.isAirportExpress" tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}">

                    {{ item.searchArgs.travellingFrom?.name }}
                    ({{ item.searchArgs.travellingFrom?.stationCode || item.searchArgs.travellingFrom?.railstation?.stationCode }}),
                    {{ item.searchArgs.travellingFrom?.locationDetails?.country || item.searchArgs.travellingFrom?.railstation?.locationDetails?.country }} -
                    {{ item.searchArgs.travellingTo?.name }}
                    ({{ item.searchArgs.travellingTo?.stationCode || item.searchArgs.travellingTo?.railstation?.stationCode }}),
                    {{ item.searchArgs.travellingTo?.locationDetails?.country || item.searchArgs.travellingTo?.railstation.locationDetails?.country }}

                  </span>
                  <span class="Title" *ngIf="item.searchArgs.isAirportExpress" tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}">
                    {{ item.searchArgs.airportExpressStation | airportExpress }}
                  </span>
                  <span class="Date"
                    >{{
                      item.searchArgs.outboundDepartureDateTime
                        ? (item.searchArgs.outboundDepartureDateTime | sabsDate: 1)
                        : (item.searchArgs.outboundArrivalDateTime | sabsDate: 1)
                    }}
                    <!-- if return jorney  -->
                    {{
                      item.searchArgs.returnDepartureDateTime
                        ? ' - ' + (item.searchArgs.returnDepartureDateTime | sabsDate: 1)
                        : ''
                    }}
                    {{
                      item.searchArgs.returnArrivalDateTime
                        ? ' - ' + (item.searchArgs.returnArrivalDateTime | sabsDate: 1)
                        : ''
                    }}
                  </span>
                  <br />
                  <br />
                  <app-recent-search-travellers
                    [travellers]="item.travellers"></app-recent-search-travellers>
                </div>
                <div class="right" (click)="removeRecentSearchItem(item, $event)" (keydown.enter)="removeRecentSearchItem(item, $event)" tabindex="0" role="button" attr.aria-labelledby="remove_recent_search_hint recent_search_{{i}}">
                  <i class="ico-cancel recentSearch_remove" aria-hidden="true"></i>
                </div>
              </div>
              <!-- InternationalRail -->
              <div
                class="storageItem"
                *ngIf="item.serviceType === serviceType.InternationalRail"
                [@fadeInOut]
                (click)="performRecentSearch(item)">
                <div class="icons">
                  <i class="ico-rail input_icon"></i>
                </div>
                <div class="details">
                  <span class="Title" tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}">
                    {{ item.searchArgs.travellingFrom?.railstation?.name }} -
                    {{ item.searchArgs.travellingTo?.railstation?.name }}
                  </span>

                  <span class="Date"
                    >{{ item.searchArgs.outboundDepartureDateTime | sabsDate: 1 }}
                    <!-- if return jorney  -->
                    <span *ngIf="item.searchArgs.journeyType === irlJourneyType.ReturnJourney">
                      {{ ' - ' + (item.searchArgs.returnDepartureDateTime | sabsDate: 1) }}
                    </span>
                  </span>
                  <br />
                  <br />
                  <app-recent-search-travellers
                    [travellers]="item.travellers"></app-recent-search-travellers>
                </div>
                <div class="right" (click)="removeRecentSearchItem(item, $event)" (keydown.enter)="removeRecentSearchItem(item, $event)" tabindex="0" role="button" attr.aria-labelledby="remove_recent_search_hint recent_search_{{i}}">
                  <i class="ico-cancel recentSearch_remove" aria-hidden="true"></i>
                </div>
              </div>
              <!-- Hotel -->
              <div
                class="storageItem"
                *ngIf="
                  item.serviceType === serviceType.Hotel && item.searchArgs.location_type_select
                "
                [@fadeInOut]
                (click)="performRecentSearch(item)">
                <div class="icons">
                  <i class="ico-bed input_icon"></i>
                </div>
                <div class="details">
                  <div [ngSwitch]="item.searchArgs.location_type_select">
                    <div *ngSwitchCase="locationTypes.Airport">
                      <span class="Title" *ngIf="item.searchArgs.iata" tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}">{{
                        item.searchArgs.airportDetail.destination 
                        ? item.searchArgs.airportDetail.destination + ' (' + item.searchArgs.iata + ')'
                        : item.searchArgs.iata | airportIata | async
                      }}</span>
                    </div>
                    <div *ngSwitchCase="locationTypes.City">
                      <span class="Title" *ngIf="item.searchArgs.cityName" tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}">{{
                        item.searchArgs.cityName
                      }}</span>
                    </div>
                    <div *ngSwitchCase="locationTypes.Office">
                      <span class="Title" *ngIf="item.searchArgs.office as office">
                        {{ office?.name }}
                      </span>
                    </div>
                    <div *ngSwitchCase="locationTypes.TrainStation">
                      <span class="Title" *ngIf="item.searchArgs.trainStationCode" tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}"
                        >{{
                          item.searchArgs.trainStationCode | railStationNameByCode | async
                        }}
                        (rail)</span
                      >
                    </div>
                    <div *ngSwitchCase="locationTypes.Shortlist">
                      <span class="Title" *ngIf="item.searchArgs.countryCode" tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}"
                        >Shortlist {{ item.searchArgs.countryCode }}</span
                      >
                    </div>
                  </div>
                  <span class="Date"
                    >{{ item.searchArgs.startDate | sabsDate: 1 }} -
                    {{ item.searchArgs.endDate | sabsDate: 1 }}
                  </span>
                  <br />
                  <br />
                  <app-recent-search-travellers
                    [travellers]="item.travellers"></app-recent-search-travellers>
                </div>
                <div class="right" (click)="removeRecentSearchItem(item, $event)" (keydown.enter)="removeRecentSearchItem(item, $event)" tabindex="0" role="button" attr.aria-labelledby="remove_recent_search_hint recent_search_{{i}}">
                  <i class="ico-cancel recentSearch_remove" aria-hidden="true"></i>
                </div>
              </div>
              <!-- Car -->
              <div
                class="storageItem"
                *ngIf="item.serviceType === serviceType.Car"
                [@fadeInOut]
                (click)="performRecentSearch(item)">
                <div class="icons">
                  <i class="ico-car input_icon" aria-hidden="true"></i>
                </div>
                <div class="details">
                  <div class="Title"  tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}">
                    <span *ngIf="item.searchArgs.city">
                      {{ item.searchArgs.city.pickupName }} ({{
                        item.searchArgs.city.pickupCountryCode
                      }}) - {{ item.searchArgs.city.dropOffName }} ({{
                        item.searchArgs.city.dropoffCountryCode
                      }})
                    </span>

                    <span *ngIf="item.searchArgs.airport">
                      {{ 
                        item.searchArgs?.airport.pickupAirportFullName
                        ? item.searchArgs?.airport.pickupAirportFullName + ' ('+ item.searchArgs?.airport.pickupCode +')' 
                        : item.searchArgs?.airport.pickupCode | airportIata | async 
                      }} -
                      {{ 
                        item.searchArgs?.airport.dropoffAirportFullName
                        ? item.searchArgs?.airport.dropoffAirportFullName + ' ('+ item.searchArgs?.airport.dropoffCode +')'
                        : item.searchArgs?.airport.dropoffCode | airportIata | async
                      }}
                    </span>

                    <span *ngIf="item.searchArgs.depot && item.searchArgs.depot.pickupVendor">
                      {{ item.searchArgs.depot.pickupVendor }}
                      {{ item.searchArgs.depot.pickupAddressLines }},
                      {{ item.searchArgs.depot.pickupCityName }} -
                      {{ item.searchArgs.depot.dropoffVendor }}
                      {{ item.searchArgs.depot.dropoffAddressLines }},
                      {{ item.searchArgs.depot.dropoffCityName }}
                    </span>
                  </div>
                  <br />
                  <span class="Date">
                    {{ item.searchArgs.pickupDateTime | sabsDate: 1 }} -
                    {{ item.searchArgs.dropoffDateTime | sabsDate: 1 }}
                  </span>
                  <br />
                  <br />
                  <app-recent-search-travellers
                    [travellers]="item.travellers"></app-recent-search-travellers>
                </div>
                <div class="right" (click)="removeRecentSearchItem(item, $event)" (keydown.enter)="removeRecentSearchItem(item, $event)" tabindex="0" role="button" attr.aria-labelledby="remove_recent_search_hint recent_search_{{i}}">
                  <i class="ico-cancel recentSearch_remove" aria-hidden="true"></i>
                </div>
              </div>
              <!-- OTHER TYPES -->
              <!-- PARKING -->
              <div
                class="storageItem"
                *ngIf="item.serviceType === serviceType.Parking"
                [@fadeInOut]
                (click)="performRecentSearch(item)">
                <div class="icons">
                  <i class="ico-parking ico-material input_icon"></i>
                </div>
                <div class="details">
                  <span class="Title" *ngIf="item.searchArgs.locationCode" tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}">
                    {{ 
                      item.searchArgs.airportFullName
                      ? item.searchArgs.airportFullName + ' (' + item.searchArgs.locationCode + ')' 
                      : item.searchArgs.locationCode | airportIata | async
                    }}
                  </span>
                  <br />
                  <span class="Date">
                    {{ item.searchArgs.arrivalDatetime | sabsDate: 1 }} -
                    {{ item.searchArgs.departDatetime | sabsDate: 1 }}
                  </span>
                  <br />
                  <br />
                  <app-recent-search-travellers
                    [travellers]="item.travellers"></app-recent-search-travellers>
                </div>
                <div class="right" (click)="removeRecentSearchItem(item, $event)" (keydown.enter)="removeRecentSearchItem(item, $event)" tabindex="0" role="button" attr.aria-labelledby="remove_recent_search_hint recent_search_{{i}}">
                  <i class="ico-cancel recentSearch_remove" aria-hidden="true"></i>
                </div>
              </div>
              <!-- LOUNGE -->
              <div
                class="storageItem"
                *ngIf="item.serviceType === serviceType.Lounge"
                [@fadeInOut]
                (click)="performRecentSearch(item)">
                <div class="icons">
                  <i class="ico-lounge ico-material input_icon"></i>
                </div>
                <div class="details">
                  <span class="Title" *ngIf="item.searchArgs.locationCode" tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}">
                    {{ 
                      item.searchArgs.airportFullName
                      ? item.searchArgs.airportFullName + ' (' + item.searchArgs.locationCode + ')'
                      : item.searchArgs.locationCode | airportIata | async 
                    }}
                  </span>
                  <br />
                  <span class="Date">
                    {{ item.searchArgs.arrivalDatetime | sabsDate: 1 }}
                  </span>
                  <br />
                  <br />
                  <app-recent-search-travellers
                    [travellers]="item.travellers"></app-recent-search-travellers>
                </div>
                <div class="right" (click)="removeRecentSearchItem(item, $event)" (keydown.enter)="removeRecentSearchItem(item, $event)" tabindex="0" role="button" attr.aria-labelledby="remove_recent_search_hint recent_search_{{i}}">
                  <i class="ico-cancel recentSearch_remove" aria-hidden="true"></i>
                </div>
              </div>
              <!-- FastTrack -->
              <div
                class="storageItem"
                *ngIf="item.serviceType === serviceType.FastTrack"
                [@fadeInOut]
                (click)="performRecentSearch(item)">
                <div class="icons">
                  <i class="ico-material ico-fast-track input_icon"></i>
                </div>
                <div class="details">
                  <span class="Title" *ngIf="item.searchArgs.locationCode" tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}">
                    {{ 
                      item.searchArgs.airportFullName
                      ? item.searchArgs.airportFullName + ' (' + item.searchArgs.locationCode + ')' 
                      : item.searchArgs.locationCode | airportIata | async
                    }}
                  </span>
                  <br />
                  <span class="Date">
                    {{ item.searchArgs.arrivalDatetime | sabsDate: 1 }}
                  </span>
                  <br />
                  <br />
                  <app-recent-search-travellers
                    [travellers]="item.travellers"></app-recent-search-travellers>
                </div>
                <div class="right" (click)="removeRecentSearchItem(item, $event)" (keydown.enter)="removeRecentSearchItem(item, $event)" tabindex="0" role="button" attr.aria-labelledby="remove_recent_search_hint recent_search_{{i}}">
                  <i class="ico-cancel recentSearch_remove" aria-hidden="true"></i>
                </div>
              </div>
              <!-- TAXI -->
              <div
                class="storageItem"
                *ngIf="item.serviceType === serviceType.Cab"
                [@fadeInOut]
                (click)="performRecentSearch(item)">
                <div class="icons">
                  <i class="ico-taxi input_icon"></i>
                </div>
                <div class="details">
                  <span class="Title"  tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}">
                    <div *ngIf="item.searchArgs?.fromLocation as fromLocation">
                      {{fromLocation.buildingName}} {{fromLocation.streetAndNumber}},
                      {{fromLocation.town}}
                      {{fromLocation.countryCode}} - 
                    </div>
                    <div *ngIf="item.searchArgs?.toLocation as toLocation">
                      {{toLocation.buildingName}} {{toLocation.streetAndNumber}},
                      {{toLocation.town}}
                      {{toLocation.countryCode}}
                    </div>
                  </span>
                  <br />
                  <span class="Date">
                    {{ item.searchArgs.departs | sabsDate: 1 }}
                  </span>
                  <br />
                  <br />
                  <app-recent-search-travellers
                    [travellers]="item.travellers"></app-recent-search-travellers>
                </div>
                <div class="right" (click)="removeRecentSearchItem(item, $event)" (keydown.enter)="removeRecentSearchItem(item, $event)" tabindex="0" role="button" attr.aria-labelledby="remove_recent_search_hint recent_search_{{i}}">
                  <i class="ico-cancel recentSearch_remove" aria-hidden="true"></i>
                </div>
              </div>
              <!-- EUROSTAR -->
              <div
                class="storageItem"
                *ngIf="item.serviceType === serviceType.Eurostar"
                [@fadeInOut]
                (click)="performRecentSearch(item)">
                <div class="icons">
                  <i class="custom_icon-eurostar input_icon"></i>
                </div>
                <div class="details">
                  <span class="Title" tabindex="0" (keydown.enter)="performRecentSearch(item)" role="button" id="recent_search_{{i}}">
                    {{ item.searchArgs.departLocation?.destination }} -
                    {{ item.searchArgs.arriveLocation?.destination }}</span
                  >
                  <br />
                  <span class="Date">
                    {{ item.searchArgs.outboundDate | sabsDate: 1 }}
                    {{
                      item.searchArgs.returnDate
                        ? ' - ' + (item.searchArgs.returnDate | sabsDate: 1)
                        : ''
                    }}
                  </span>
                  <br />
                  <br />
                  <app-recent-search-travellers
                    [travellers]="item.travellers"></app-recent-search-travellers>
                </div>
                <div class="right" (click)="removeRecentSearchItem(item, $event)" (keydown.enter)="removeRecentSearchItem(item, $event)" tabindex="0" role="button" attr.aria-labelledby="remove_recent_search_hint recent_search_{{i}}">
                  <i class="ico-cancel recentSearch_remove" aria-hidden="true"></i>
                </div>
              </div>
            </ng-container>
          </li>
        </ul>
      </div>
      <div class="recent_searches_no_recent" *ngIf="recentSearches.length === 0">
        <i class="ico-search no_recent_icon" aria-hidden="true"></i>
        <h2 class="no_recent_header" i18n="@@YouHaveNoRecentSearches">
          You have no recent searches
        </h2>
        <p class="no_recent_tag_line" i18n="@@YouHaveNoRecentSearchesMessage">
          All searches you perform will appear here so you can quickly pick up where you left off
          anytime
        </p>

        <p></p>
      </div>
    </ng-container>
    <span id="remove_recent_search_hint" hidden>Remove recent search</span>
  </div>
</section>

