import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  EnterpriseSearchService,
  UserService,
  StorageService,
  WithSubscriptionComponent,
} from '@sabstravtech/obtservices/angular';
import { DeviceDetector } from '../../../../../vendor/services/device-detector.service';
@Component({
  selector: 'app-flight-extras-recalculate',
  templateUrl: './flight-extras-recalculate.component.html',
  styleUrls: ['./flight-extras-recalculate.component.scss']
})
export class FlightExtrasRecalculateComponent extends WithSubscriptionComponent implements OnInit {
  @Input() operatingCarrierName: string = null;
  @Input() loyaltyCode: string = "";
  @Input() dob: number = null;
  @Input() isGuest: boolean = false;
  @Input() travellerId: string = "";
  @Input() dobMandatory: boolean = false;
  @Output() loyaltyCodeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() recalculate: EventEmitter<void> = new EventEmitter<void>();
  @Output() setTravellerDob: EventEmitter<NgbDateStruct> = new EventEmitter<NgbDateStruct>();
  today: Date = new Date();

  minDate: NgbDateStruct = { year: this.today.getFullYear() - 100, month: 1, day: 1 };  // 100 years ago
  maxDate: NgbDateStruct = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };  // Today
  startDate: NgbDateStruct = { year: this.today.getFullYear() - 30, month: this.today.getMonth() + 1, day: this.today.getDate() }; // 30 years ago
  recalcActive: boolean = false;
  loyaltyClone: string = "";
  chosenDob: NgbDateStruct = null;
  constructor(
    public activeModal: NgbActiveModal,
    public searchService: EnterpriseSearchService,
    public userService: UserService,
    public deviceDetector: DeviceDetector,
    public storageService: StorageService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dob']?.currentValue) {
      this.dob = changes['dob'].currentValue;
      this.convertToNgbDateStruct();
    }
  }

  ngOnInit() {
    this.loyaltyClone = this.loyaltyCode;
    if (this.dob) {
      this.convertToNgbDateStruct();
    }
  }

  convertToNgbDateStruct() {
    const date = new Date(this.dob);
    this.chosenDob = {
      year: date.getFullYear(),
      month: date.getMonth() + 1, // JavaScript months are 0-based
      day: date.getDate()
    };
  }

  updateLoyaltyCode(event: any) {
    this.loyaltyClone = event.target.value;
    this.recalcActive = this.loyaltyCheck();
  }

  loyaltyCheck() {
    if (this.loyaltyClone !== this.loyaltyCode) {
      return true;
    }
    return false;
  }

  loyaltyCheckWithDob() {
    this.setTravellerDob.emit(this.chosenDob);
    if (this.loyaltyCode) {
      this.recalcActive = true;
    }
  }



  recalcuateSeats() {
    if (!this.dob && this.chosenDob) {
      // Helpers.formatIsoDate(moment(this.chosenDob)) + 'T00:00:00Z' : null,
      // const timeStamp = Helpers.formatIsoDate(moment(this.chosenDob)) + 'T00:00:00Z';
      const timeStamp = `${this.chosenDob.year}-${String(this.chosenDob.month).padStart(2, '0')}-${String(this.chosenDob.day).padStart(2, '0')}`;
      this.subscribe(this.searchService.updateSelecedtravallerDob(timeStamp, this.travellerId, this.isGuest), data => {
        if (data) {
          this.loyaltyCodeChanged.emit(this.loyaltyClone);
          this.recalculate.emit();
          this.recalcActive = false;
        } else {
          console.warn('DOB not updated');
        }
      });
      // this.updateDob.emit(timeStamp);
    } else {
      this.loyaltyCodeChanged.emit(this.loyaltyClone);
      this.recalculate.emit();
      this.recalcActive = false;
    }
  }

  convertToTimestamp(date: NgbDateStruct): string {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return jsDate.getTime().toString();
  }
}


