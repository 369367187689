<ng-template #emailrt let-r="result" let-t="term">
  <div class="item-search-select">
    <span class="item-dest"
      >{{ r?.title }} {{ r?.forename }} {{ r?.surname }} ({{ r?.email }})</span
    >
  </div>
</ng-template>
<div cdkTrapFocus *ngIf="approvalLevel === 1" aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title" id="modal-header">{{ title | translate }}</h3>
    <button
      type="button"
      class="close"
      title="Close Modal"
      attr.aria-label="{{ title }} Close"
      ngbAutoFocus
      i18n-aria-label
      (click)="activeModal.close(false)">
      <span aria-hidden="true">
        <i class="ico-cancel modal_exit_cross"></i>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="isSelectedApprover" class="approver-list-row">
      <span
        id="approvers-select"
        *ngIf="isSelectedApprover && isSelectedNotifyApprover"
        i18n="@@Select Approver"
        >Select Approver</span
      >
      <input
        class="input-box input-box--border"
        aria-labelledby="approvers-select"
        ngbAutoFocus
        [ngbTypeahead]="searchUsers"
        [resultTemplate]="emailrt"
        [(ngModel)]="chosen_approver"
        placeholder="Choose an approver"
        [inputFormatter]="userFormatter"
        i18n-placeholder
        (click)="outboundClick$.next($event.target.value)"
        type="text"
        popupClass="dropdown-full_width-main" />
    </div> 
    <div *ngIf="isSelectedMIApprover" class="approver-list-row">
      <span
        id="mi-approvers-select"
        *ngIf="isSelectedMIApprover && isSelectedNotifyApprover"
        i18n="@@Select Approver"
        >Select Approver</span
      >
      <input
        class="input-box input-box--border"
        aria-labelledby="approvers-select"
        ngbAutoFocus
        [ngbTypeahead]="searchUsers"
        [resultTemplate]="emailrt"
        [(ngModel)]="chosen_approver"
        placeholder="Choose an approver"
        [inputFormatter]="userFormatter"
        i18n-placeholder
        (click)="outboundClick$.next($event.target.value)"
        type="text"
        popupClass="dropdown-full_width-main" />
    </div>
    <div *ngIf="isSelectedNotifyApprover" class="approver-list-row">
      <span id="notify-approvers-select" i18n="@@SelectNotifyApprover">Select Notify Approver</span>

      <input
        class="input-box input-box--border"
        aria-labelledby="notify-approvers-select"
        ngbAutoFocus
        [ngbTypeahead]="searchUsers"
        [resultTemplate]="emailrt"
        [(ngModel)]="chosenNotifyApprover"
        placeholder="Choose an approver"
        [inputFormatter]="userFormatter"
        i18n-placeholder
        (click)="outboundClick$.next($event.target.value)"
        type="text"
        popupClass="dropdown-full_width-main" />
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      [disabled]="
        ((isSelectedApprover || isSelectedMIApprover) && !chosen_approver) ||
        (isSelectedNotifyApprover && !chosenNotifyApprover)
      "
      id="button_ok"
      class="modal_button_okay"
      (click)="closeModal()"
      i18n="@@approversModalSelect">Select Approver</button>
  </div>
</div>
<div cdkTrapFocus aria-live="assertive" *ngIf="approvalLevel > 1">
  <div class="modal-header">
    <h3 class="modal-title" id="modal-header">{{ title | translate }}</h3>
    <button
      type="button"
      class="close"
      title="Close Modal"
      attr.aria-label="{{ title }} Close"
      ngbAutoFocus
      i18n-aria-label
      (click)="activeModal.close(false)">
      <span aria-hidden="true">
        <i class="ico-cancel modal_exit_cross"></i>
      </span>
    </button>
  </div>
  <form #approverLevelForm="ngForm">
    <div class="modal-body">
      <div class="approver-list-row">
        <section *ngFor="let level of approversLevel; let i = index">
          <div class="approver_level">
            <span id="approvers-select{{ level.level }}" i18n="@@Select_approver_level"
              >Select Approver for level </span
            ><span>{{ level.level }}</span>
            <input
              [disabled]="i > 0 && !approversLevel[i - 1].chosenApprover"
              required
              class="input-box input-box--border"
              aria-labelledby="approvers-select{{ level.level }}"
              id="approver_select_level_{{ level.level }}"
              name="approver_select_level_{{ level.level }}"
              [ngbTypeahead]="searchUsersLevel($text, instance, i)"
              [resultTemplate]="emailrt"
              [(ngModel)]="level.chosenApprover"
              placeholder="Choose an approver"
              [inputFormatter]="userFormatter"
              i18n-placeholder
              (click)="levelClick$[i].next($any($event).target.value)"
              type="text"
              popupClass="dropdown-full_width-main"
              #instance="ngbTypeahead"
              (selectItem)="updateApprovers($event, i)" />
          </div>
        </section>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <button
      type="button"
      [disabled]="approverLevelForm.form.invalid"
      id="button_ok"
      class="modal_button_okay"
      (click)="closeModal()"
      i18n="@@approversModalSelect">Select Approver</button>
  </div>
</div>
