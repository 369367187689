<div cdkTrapFocus aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title" i18n="@@modalTitleDetailsAndAllFares" id="modal-header">
      Details and all fares
    </h3>
    <button
      type="button"
      class="close"
      aria-label="Details and all fares"
      ngbAutoFocus
      (click)="close()">
      <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <mat-tab-group dynamicHeight class="rail-tabs">
      <mat-tab label="All available fares" i18n-label>
        <div *ngFor="let fare of railFare?.fares; let i=index" class="flex-row-container align-items-center justify-content-between pt-2 pb-2">
          <div class="item-description">
            <div class="price-container">
              <div><strong>{{fare.price | currency: fare.currency}} {{fare.type}}</strong></div>
              <a
                class="gmt-fare-rules-link"
                (click)="openRailTicketDetailsDialog(railFare, i)"
                (keydown.enter)="openRailTicketDetailsDialog(railFare, i)"
                tabindex="0"
                role="link">
                <i class="gmt-currency-info-icon currency_info_icon" aria-hidden="true"></i>
                <span class="visuallyhidden">{{fare.price | currency: fare.currency}} {{fare.type}} ({{railFare?.journey.departDateTime | date: 'dd/MM/yyyy'}} {{removeTimeZone(railFare?.journey.departDateTime) | sabsTimePipe : 'timeString'}} - {{removeTimeZone(railFare?.journey.arriveDateTime) | sabsTimePipe : 'timeString'}}) rail fare&nbsp;</span>
                <span i18n="@@boltTicketConditions">Ticket Conditions</span>
              </a>
            </div>
          </div>
          <div class="item-button-container">
            <app-warning-tags [ticket]="fare" [displayCheck]="false"></app-warning-tags>
            <button class="small-button" [disabled]="fare.unavailable" (click)="selectNewFare(i, fare?.fareHash, selectedFare !== fare?.fareHash)">
              {{ (selectedFare === fare?.fareHash ? 'Deselect' : 'Select')| translate }} 
              <span class="visuallyhidden">&nbsp;{{fare.price | currency: fare.currency}} {{fare.type}} ({{railFare?.journey.departDateTime | date: 'dd/MM/yyyy'}} {{removeTimeZone(railFare?.journey.departDateTime) | sabsTimePipe : 'timeString'}} - {{removeTimeZone(railFare?.journey.arriveDateTime) | sabsTimePipe : 'timeString'}}) rail fare</span>
            </button>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Train Journey Details" i18n-label>
        <app-rail-journey-segments [segments]="segments"></app-rail-journey-segments>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="modal_button_okay"
      (click)="close()"
      i18n="@@modalRailJourneyDetailsOK">
      Ok
    </button>
  </div>
</div>
