import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BasketItem,
  WithSubscriptionComponent,
  FlightJourney,
  EnterpriseSearchService,
  FareConditions,
  ServiceType,
  FlightExtrasOptions,
  HelperRoutines
} from '@sabstravtech/obtservices/angular';
import { AirportIataFormat } from '../../../../startup/pipes/airport-iata.pipe';
import { UiHelpers } from '../../../../vendor/classes/ui-helpers';
import { FlightBasketDetails, Journey } from '../../../../vendor/interfaces/flight-basket-details';
import { BasketDetails, FlightEnterpriseSearchInterface, FlightItineraryWithExtensions, TravellerObject } from '@sabstravtech/obtservices/base';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-basket-flight',
  templateUrl: './basket-flight.component.html',
  styleUrls: ['./basket-flight.component.scss']
})
export class BasketFlightComponent extends WithSubscriptionComponent implements OnInit {
  @Input() flight: BasketItem;
  @Input() duplicateBooking = false;
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() openChangeModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() displayItinerary = false;
  AirportIataFormat: typeof AirportIataFormat = AirportIataFormat;
  flightsToDisplay: BasketItem;
  public searchParams: FlightEnterpriseSearchInterface;
  extras = [];
  journeys: Journey[];
  disableEditButton: boolean = false;
  hasSeats: boolean = false;
  constructor(private searchService: EnterpriseSearchService, private helpers: HelperRoutines) {
    super();
  }
  travellers = [];
  ngOnInit(): void {
    this.searchParams = this.searchService.searches[ServiceType.Flight];
    this.flightsToDisplay = { ...this.flight };
    let outboundFlights = this.flight.detail.outboundFlights;
    let inboundFlights = this.flight.detail.inboundFlights;
    if (this.duplicateBooking) {
      this.addJourneys(outboundFlights, "outbound");
      this.addJourneys(inboundFlights, "inbound");
    }
    this.flightsToDisplay.detail.flights = [...outboundFlights, ...inboundFlights];
    // we need to get passenger details and map them to seats and amenities
    console.log(this.flight.detail);
    this.makeJourneys(this.flight.detail.flights);
    this.travellers = [...this.flight?.users, ...this.flight?.guests];
    this.extras = this.travellers.map((user, index) => {
      let isInbound;
      if (this.flight.detail.inboundFlights.length) {
        isInbound = this.getAdditionalExtras(this.flight.detail.inboundFlights, index);
      } else {
        isInbound = [];
      }
      return {
        seatsOutbound: this.getSeatDetails(this.flight.detail.outboundFlights, user.id),
        seatsInbound: this.getSeatDetails(this.flight.detail.inboundFlights, user.id),
        additionalOutbound: this.getAdditionalExtras(this.flight.detail.outboundFlights, index),
        additionalInbound: isInbound
      };
    });
    this.checkIfWithin8Minutes();
    console.log(this.extras);
  }

  checkIfWithin8Minutes() {
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - new Date(this.flight.createdAt).getTime();
    const eightMinutes = 8 * 60 * 1000;

    // Calculate remaining time
    const remainingTime = eightMinutes - timeDifference;

    if (remainingTime > 0) {
      // Show button initially and hide it after the remaining time
      this.disableEditButton = false;
      setTimeout(() => this.disableEditButton = true, remainingTime);
    } else {
      // If more than 10 minutes have already passed, hide the button
      this.disableEditButton = true;
    }
  }

  getSeatDetails(
    flights: FlightBasketDetails[],
    travellerId: string
  ): {
    seats: {
      seat: string;
      price: number;
      currency: string;
      userId: string;
    };
  } {
    const seats = [];
    let price = 0;
    let currency: string;
    let userId: string;
    flights.forEach(flight => {
      const matchingIdSeat = flight.additional?.seat?.find(seatDetail => seatDetail.userId === travellerId);
      if (flight.additional?.seat && matchingIdSeat) {
        const seat = matchingIdSeat;
        seats.push(`${seat.row}-${seat.column}`);
        price += seat.price;
        currency = seat.currency;
        userId = seat.userId;
      }
    });
    if (seats.length && !this.hasSeats) {
      this.hasSeats = true;
    }
    return {
      seats: {
        seat: seats.join(','),
        price,
        currency,
        userId
      }
    };
  }

  getAdditionalExtras(
    flights: FlightBasketDetails[],
    index: number
  ): {
    advancedAncillaryOptions: FlightExtrasOptions;
    flightExtras: FlightExtrasOptions;
    reducedMobillityAssistance: FlightExtrasOptions;
    baggageAllowance: FlightExtrasOptions;
  } {
    if (flights?.length) {
      const {
        advancedAncillaryOptions,
        flightExtras,
        reducedMobillityAssistance,
        baggageAllowance
      } = flights[0]?.additional;
      return {
        advancedAncillaryOptions: advancedAncillaryOptions ? advancedAncillaryOptions[index] : null,
        flightExtras: flightExtras ? flightExtras[index] : null,
        reducedMobillityAssistance: reducedMobillityAssistance
          ? reducedMobillityAssistance[index]
          : null,
        baggageAllowance: baggageAllowance ? baggageAllowance[index] : null
      };
    }
  }

  makeJourneys(flights: FlightBasketDetails[]): void {
    console.log('flights ', flights);
    //if(this.duplicateBooking) return flights;
    this.journeys = UiHelpers.makeJourneys(flights);
  }

  getFareRules(flightIndex: number): void {
    const flight = this.helpers.clone(this.journeys[flightIndex]);
    delete flight.baggageAllowanceAmount;
    const rules: FareConditions = flight.fareConditions;
    const flights: FlightJourney[] = this.helpers.getFlightsForFareRules(flight as FlightJourney, this.flight);
    this.searchParams.openFlightFareRules(rules, flights, parseInt(this.flight.service.id), {}, flight as FlightJourney);
  }

  addJourneys(flights, type: string) {
    flights.forEach((flight) => {
      flight.journeyInfo = {
        flightGroup: type,
        groupIndex: 0
      };
    });
  }

  async openSeatMapModal(): Promise<void> {
    const flightCopy = <FlightItineraryWithExtensions>this.helpers.clone(this.flight.detail);
    flightCopy.source = this.flight.service.name;

    const users = this.flight.users.map(user => TravellerObject.makeTravellerObjectFromUser(user));
    const guests = this.flight.guests.map(guest => {
      const returnObject = TravellerObject.makeTravellerObjectFromUser(guest);
      returnObject.guest = true;
      return returnObject;
    });

    const travellers = [...users, ...guests];
    const detailExtras = await this.searchParams.getFlightDetailExtras(
      flightCopy,
      this.flight.detail.journeyType,
      false,
      travellers
    );

    if (detailExtras?.valid) {
      const extraSeatKeys = Object.keys(detailExtras.seats);

      // Loop over each seatKey and seatDetail sequentially using async/await
      for (const seatKey of extraSeatKeys) {
        const seatDetailArray = detailExtras.seats[seatKey];

        for (const seatDetail of seatDetailArray) {
          delete seatDetail.__typename;
          seatDetail.attributes.forEach(attr => delete attr.__typename);

          const data: BasketDetails = await lastValueFrom(this.searchParams
            .updateFlightSeatMap(seatDetail.userId, this.flight.id, seatKey, seatDetail));

          // Update flight and extras based on the response
          const matchingBasketItem = data.basketItems.find(basketItem => basketItem.id === this.flight.id);
          this.flight = <BasketItem>matchingBasketItem;
          console.log(data);

          // Update `extras` based on the current state of `this.flight`
          this.extras = this.travellers.map((user, index) => {
            const isInbound = this.flight.detail.inboundFlights.length
              ? this.getAdditionalExtras(this.flight.detail.inboundFlights, index)
              : [];

            return {
              seatsOutbound: this.getSeatDetails(this.flight.detail.outboundFlights, user.id),
              seatsInbound: this.getSeatDetails(this.flight.detail.inboundFlights, user.id),
              additionalOutbound: this.getAdditionalExtras(this.flight.detail.outboundFlights, index),
              additionalInbound: isInbound
            };
          });
        }
      }
    }
  }

}

