import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  LOCALE_ID,
  Inject,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';
import {
  HotelEnterpriseSearchInterface,
  HotelQuoteResult,
  filtersKeys,
  SearchHandler,
  AmenitiesSearchHandler,
  ExtraSearchHandler,
  HotelDetails,
  RoomType,
  TravelPolicies,
  StarRating,
  CustomerRating,
  HotelUIStarsUserFavoriteResponse
} from '@sabstravtech/obtservices/base';
import {
  ServiceType,
  EnterpriseSearchService,
  UserService,
  HotelAvalibilityService,
  WithSubscriptionComponent
} from '@sabstravtech/obtservices/angular';
import { LightningUserFavorurite } from '../../../../vendor/classes/user-favourite.enum';
import { HotelTagEnum } from '../../../../vendor/enum/hotel-tag.enum';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-filter-hotels',
  templateUrl: './filter-hotels.component.html',
  styleUrls: ['./filter-hotels.component.scss']
})
export class FilterHotelsComponent extends WithSubscriptionComponent implements OnInit, OnChanges {
  @Input() srchRslts: any;
  @Input() swapSellHotelGroup: string;
  @Input() hotels: HotelQuoteResult[];
  @Input() viewControl: any;
  @Input() showFilts: boolean;
  @Output() availabilityFilter: EventEmitter<boolean> = new EventEmitter<boolean>();

  amenityTypeList: string[];
  chainTypeList: string[];
  hotelPriceRange: number[];
  hotelName: string;
  formFilter: FormGroup = new FormGroup({
    HotelName: new FormControl('')
  });
  amenitiesShowMore = false;
  chainsShowMore = false;
  priceOptions: Options;
  hotelNameTimeout = null;
  public showIt: boolean = false;
  maxPrice: number = 0;
  minPrice: number = 0;
  readonly Defaulttop10amenty: AmenitiesSearchHandler[] = [
    AmenitiesSearchHandler.AIR_CONDITIONING,
    AmenitiesSearchHandler.BUSINESS_CENTRE,
    AmenitiesSearchHandler.EXPRESS_CHECKOUT,
    AmenitiesSearchHandler.GOLF_FACILITIES,
    AmenitiesSearchHandler.PARKING,
    AmenitiesSearchHandler.RESTAURANT,
    AmenitiesSearchHandler.SWIMMING_POOL,
    AmenitiesSearchHandler.WHEELCHAIR_ACCESS,
    AmenitiesSearchHandler.WIFI,
    AmenitiesSearchHandler.ON_SITE_SAFE,
    AmenitiesSearchHandler.ROOM_SERVICE_24_HOUR,
    AmenitiesSearchHandler.ELECTRIC_CAR_CHARGING_POINT
  ];

  readonly extra: string[] = [SearchHandler.AVAILABILITY, SearchHandler.FEMALE_FRIENDLY];
  readonly preferred: string[] = [SearchHandler.ANY, SearchHandler.AGENCY, SearchHandler.CLIENT];
  readonly travelPolicies: string[] = [TravelPolicies.ALLOW_WITH_REASON, TravelPolicies.IN_POLICY];
  readonly starRating: string[] = [
    StarRating.TwoStar,
    StarRating.ThreeStar,
    StarRating.FourStar,
    StarRating.FiveStar,
    StarRating.Unrated
  ];
  readonly customerRating: string[] = [
    CustomerRating.OneStar,
    CustomerRating.TwoStar,
    CustomerRating.ThreeStar,
    CustomerRating.FourStar,
    CustomerRating.FiveStar,
    CustomerRating.Unrated
  ];
  readonly preferredCrown: string[] = [SearchHandler.CROWN_ONLY, SearchHandler.NON_CROWN_ONLY];
  readonly refundableRoomTypeList: string[] = [
    SearchHandler.REFUNDABLE,
    SearchHandler.NON_REFUNDABLE
  ];
  readonly roomTypeList: string[] = [
    RoomType.SingleRoom,
    RoomType.DoubleRoom,
    RoomType.TwinRoom,
    RoomType.QueenRoom,
    RoomType.KingRoom,
    RoomType.Suite
  ];

  readonly breakfastFilters: string[] = [
    SearchHandler.BREAKFAST_INCLUDED,
    SearchHandler.BREAKFAST_NOT_INCLUDED
  ];

  readonly amenityTypeListAdditional: string[] = [
    ExtraSearchHandler.GREEN_HOTEL,
    ExtraSearchHandler.ACCESSIBLE_ROOM,
    ExtraSearchHandler.CTM_PARTNERS
  ];

  SearchHandler: typeof SearchHandler = SearchHandler;
  ExtraSearchHandler: typeof ExtraSearchHandler = ExtraSearchHandler;
  AmenitiesSearchHandler: typeof AmenitiesSearchHandler = AmenitiesSearchHandler;
  public searchParams: HotelEnterpriseSearchInterface;
  get top10amenty(): { [key: string]: boolean; } {
    const additionalFiltersHotel =
      this.userService.getUserFavoriteValue(LightningUserFavorurite.additionalFiletersHotel) || '';
    return [...this.Defaulttop10amenty, ...additionalFiltersHotel.split('|')].reduce(
      (current: { [key: string]: boolean; }, amenity: string) => {
        if (amenity) {
          current[amenity] = true;
        }
        return current;
      },
      {}
    );
  }

  CheckboxStatesFacilities = {};
  CheckboxStatesExtra = {};
  CheckboxStatesBreakfast = {};
  CheckboxStatesPreference = {};
  CheckboxStatesPreferenceCrown = {};
  CheckboxStatesChain = {};
  CheckboxStatesRefundable = {};
  CheckboxStatesTravelPolicy = {};
  CheckboxStatesStarRating = {};
  CheckboxStatesCustomerRating = {};
  CheckboxAmenityTypes = {};
  isFCDOApprovedHotel = false;
  hotelTagEnum = HotelTagEnum;
  crownSet = false;
  public hotelStarRatingsToHide = {
    [StarRating.TwoStar]: false,
    [StarRating.ThreeStar]: false,
    [StarRating.FourStar]: false,
    [StarRating.FiveStar]: false
  }
  constructor(
    public searchService: EnterpriseSearchService,
    @Inject(LOCALE_ID) public readonly locale: string,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    public hotelAvalibilityService: HotelAvalibilityService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.searchParams = this.searchService.searches[ServiceType.Hotel];
    console.log(this.searchParams.maxPrice);
    console.log(this.searchParams.filters.priceRange);
  }

  ngOnInit(): void {
    this.crownSet = this.userService.crownIsSet();
    console.warn(this.searchParams.filters);

    this.amenityTypeList = [];
    this.chainTypeList = [];
    this.hotelPriceRange = [];
    this.createOptionsList();
    this.setPreferredOptions();
    this.setPriceFilterValues();
    this.subscribe(this.searchParams.hotelRoomReceived, (data: boolean) => {
      if (data) {
        this.setPriceFilterValues();
      }
    });
    this.isFCDOApprovedHotel = this.searchParams.resultsHaveTag(HotelTagEnum.FCDOApprovedHotel);
    this.clearFilters();
    if (this.searchParams.filters.crownPreferred.includes(SearchHandler.CROWN_ONLY)) {
      this.CheckboxStatesPreferenceCrown[SearchHandler.CROWN_ONLY] = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.swapSellHotelGroup) {
      const currentValues = changes.swapSellHotelGroup.currentValue;
      if (Array.isArray(currentValues)) {
        currentValues.forEach(currentValue => {
          this.CheckboxStatesChain[currentValue] = true;
        });
      } else {
        this.CheckboxStatesChain[currentValues] = true;
      }
    }
  }

  setPriceFilterValues(): void {
    let min: number, max: number;
    let minValue = [];
    let maxValue = [];
    if (
      Math.round(this.hotelPriceRange[0]) !== Math.round(this.searchParams.filters.priceRange.min)
    ) {
      min = this.searchParams.filters.priceRange.min;
    }
    if (
      Math.round(this.hotelPriceRange[1]) !== Math.round(this.searchParams.filters.priceRange.max)
    ) {
      max = this.searchParams.filters.priceRange.max;
    }

    this.searchParams.fullResults.forEach((hotel: HotelDetails) => {
      const availability = this.searchParams.getAvaliability(hotel);
      if (availability && Object.keys(availability).length !== 0 && availability?.rooms[0]) {
        const conversion = availability?.rooms[0]?.currencyConversion?.rate || 1;
        // minValue
        minValue.push(availability.prpn * conversion);
        // maxValue
        if (availability?.maxprice) {
          maxValue.push(availability.maxprice * conversion);
        } else {
          maxValue.push(
            availability?.rooms.reduce((op, item) => (op = op > item.total ? op : item.total), 0)
          );
        }
      }
    });

    let minPrice = minValue.length ? Math.min(...minValue) : 0;
    let maxPrice = maxValue.length
      ? Math.max(...maxValue)
      : this.searchParams.filters.priceRange.max;
    this.searchParams.filters.perNightPriceRange.min = this.searchParams.filters.priceRange.min =
      min || minPrice;
    this.searchParams.filters.perNightPriceRange.max = this.searchParams.filters.priceRange.max =
      max || maxPrice;
    this.hotelPriceRange = [minPrice, maxPrice];
  }

  setPreferredOptions(): void {
    const Any = 'Any';
    const Client = 'Client';
    const Agency = 'Agency';

    // this.srchRslts.forEach((hotel: Hotel) => {
    //   if (+hotel.Preferred === 1 && !this.preferred.includes(Client)) {
    //     this.preferred.push(Client);
    //   }

    //   if (+hotel.Preferred === 2 && !this.preferred.includes(Agency)) {
    //     this.preferred.push(Agency);
    //   }

    //   if (+hotel.Preferred === 3 && !this.preferred.includes(Any)) {
    //     this.preferred.push(Any);
    //   }
    // });
  }

  createOptionsList(): void {
    const top10amenty = this.top10amenty;
    const [cHeckChain1]: [{ [key: string]: boolean; }] = this.searchParams.results.value.reduce(
      ([cHeckChain]: [{ [key: string]: boolean; }], hotel) => {
        if (!cHeckChain[hotel.group]) {
          cHeckChain[hotel.group] = true;
        }
        return [cHeckChain];
      },
      [{}]
    );

    this.amenityTypeList = Object.keys(top10amenty).filter(item => !this.searchParams.hiddenAmenitiesFilters[item]).sort();;
    this.initialiseStates();
    this.chainTypeList = Object.keys(cHeckChain1).sort();
  }

  initialiseStates() {
    this.amenityTypeList.forEach(item => {
      this.CheckboxStatesFacilities[item] = false;
    });

    this.extra.forEach(item => {
      this.CheckboxStatesExtra[item] = false;
    });

    this.refundableRoomTypeList.forEach(item => {
      this.CheckboxStatesRefundable[item] = false;
    });

    this.breakfastFilters.forEach(item => {
      this.CheckboxStatesBreakfast[item] = false;
    });

    this.travelPolicies.forEach(item => {
      this.CheckboxStatesTravelPolicy[item] = false;
    });

    this.preferred.forEach(item => {
      this.CheckboxStatesPreference[item] = false;
    });

    this.preferredCrown.forEach(item => {
      this.CheckboxStatesPreferenceCrown[item] = false;
    });

    this.roomTypeList.forEach(item => {
      this.CheckboxStatesPreference[item] = false;
    });

    this.chainTypeList.forEach(item => {
      this.CheckboxStatesChain[item] = false;
    });

    this.amenityTypeListAdditional.forEach(item => {
      this.CheckboxAmenityTypes[item] = false;
    });

    const uiConfigHotelRatings = this.userService.getUserFavoriteObject<HotelUIStarsUserFavoriteResponse>(LightningUserFavorurite.hotelPreferredImage);
    if (uiConfigHotelRatings?.hotelStarsToHide) {
      if (uiConfigHotelRatings.hotelStarsToHide.hideTwoStarHotels) {
        this.hotelStarRatingsToHide[StarRating.TwoStar] = true;
      }
      if (uiConfigHotelRatings.hotelStarsToHide.hideThreeStarHotels) {
        this.hotelStarRatingsToHide[StarRating.ThreeStar] = true;
      }
      if (uiConfigHotelRatings.hotelStarsToHide.hideFourStarHotels) {
        this.hotelStarRatingsToHide[StarRating.FourStar] = true;
      }
      if (uiConfigHotelRatings.hotelStarsToHide.hideFiveStarHotels) {
        this.hotelStarRatingsToHide[StarRating.FiveStar] = true;
      }
    }

    console.log(this.CheckboxAmenityTypes);
  }

  clearFilters(): void {
    this.minPrice = 0;
    this.maxPrice = 0;
    this.searchParams.filters.perNightPriceRange.min = this.searchParams.filters.priceRange.min = 0;
    this.searchParams.filters.perNightPriceRange.max = this.searchParams.filters.priceRange.max =
      Number.MAX_SAFE_INTEGER;
    this.searchParams.resetFilters();
    this.searchParams.filterLighteningResults();
    this.initialiseStates();
    this.emitChange();
    console.log(this.CheckboxAmenityTypes);
  }

  updatePrice() {
    if (+this.searchParams.filters.priceRange.max > +this.searchParams.filters.priceRange.min) {
      this.searchParams.filters.perNightPriceRange.max = this.searchParams.filters.priceRange.max =
        +this.maxPrice;
      this.searchParams.filters.perNightPriceRange.min = this.searchParams.filters.priceRange.min =
        +this.minPrice;
      this.emitChange();
    }
  }

  closeFiltersOptions(): void {
    this.viewControl.closeFilterOptions();
  }

  public applyFilter({ checked }, filterString: string, filterType: string): void {
    if (checked) {
      this.searchParams.addFilter(filterString, filtersKeys[filterType]);
    } else {
      this.searchParams.removeFilter(filterString, filtersKeys[filterType]);
    }

    this.searchParams.filterChange.next(true);
    this.emitChange();
  }

  // Search for the hotel name
  public onFilterChangeHotelName(): void {
    // @ts-ignore
    if (window.document.documentMode) {
      //On IE so delay the filter
      const context = this;
      clearTimeout(this.hotelNameTimeout);
      this.hotelNameTimeout = setTimeout(function () {
        context.searchParams.filterChange.next(true);
      }, 1500);
    } else {
      this.onFilterChange();
    }
  }

  public onFilterChange(): void {
    this.searchParams.filterChange.next(true);
  }

  trapFocus(event, i, array): void {
    if (i === array.length - 1) {
      event.preventDefault();
      const focusable = document
        .getElementById('filters')
        .querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
      // @ts-ignore
      focusable[0].focus();
    }
  }

  trapFocusReverse(event): void {
    event.preventDefault();
    const focusable = document
      .getElementById('filters')
      .querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    // @ts-ignore
    focusable[focusable.length - 1].focus();
  }

  emitChange() {
    this.searchParams.filterLighteningResults();
  }

  getExtraDisplayString(code: string) {
    if (code === SearchHandler.AVAILABILITY) {
      return 'Available Hotels';
    }
    return code;
  }

  setFocus(string) {
    setTimeout(() => {
      this.document.getElementById(string).focus();
    }, 0);
  }

  getAmenitiesDisplayText(code: string): string {
    switch (code) {
      case AmenitiesSearchHandler.SWIMMING_POOL:
        return 'Swimming Pool';
      case AmenitiesSearchHandler.WIFI:
        return 'Wifi';
      default:
        return code;
    }
  }
}

