<div class="modal-header">
  <h3 class="modal-title" i18n="@@hotelPhotos">Hotel Photos</h3>
  <button type="button" class="light-icon-button close" aria-label="Close" (click)="activeModal.close(false)" ngbAutoFocus>
    <i class="ico-cancel AA-standard-grey" aria-hidden="true"></i>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="hotelImages?.length && !loading">
    <div class="hotel-gallery" [ngClass]="{'visuallyhidden' : imagesLoaded < hotelImages.length}">
      <ng-container *ngFor="let image of hotelImages; let index = index">
        <div [ngClass]="{'highlighted-hotel': image === selectedHotel}" 
             (click)="selectedHotel = image" 
             (keydown.enter)="selectedHotel = image" 
             tabindex="0" role="button" 
             aria-label="Select photo {{index}} for display" 
             [attr.aria-pressed]="image === selectedHotel">
          <img [src]="image" alt="Photo {{index}}" (load)="imagesLoadedCount()">
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="selectedHotel">
      <div class="selected-image"  [ngClass]="{'visuallyhidden' : imagesLoaded < hotelImages.length}">
        <img [src]="selectedHotel" alt="Selected hotel image" >
      </div>
    </ng-container>
    <div *ngIf="imagesLoaded < hotelImages.length">
      <i class="button_spinner"></i>
    </div>
  </ng-container>
  
  <div *ngIf="!hotelImages?.length && loading">
    <i class="button_spinner"></i>
  </div>
  <div *ngIf="!hotelImages?.length && !loading" i18n="@@noPhotos">
    No photos...
  </div>
</div>
