<div class="header_bar" id="results_header_bar">
  <div
    class="brand-title header_bar_nav_left"
    [ngClass]="{ 'brand-title__trim_mobile': hasStandardLogo() }">
    <a
      href="#main"
      accesskey="s"
      class="sr-only sr-only-focusable btn btn-link"
      (click)="focusContainer('main')"
      role="link"
      (keydown.enter)="focusContainer('main')"
      tabindex="0"
      i18n="@@resultsHeaderSkipNavigation"
      >Skip navigation</a
    >
    <a
      class="sr-only sr-only-focusable btn btn-link"
      (click)="focusBasketButton()"
      (keydown.enter)="focusBasketButton()"
      i18n="@@resultsHeaderGoToRecentSearches"
      tabindex="0"
      role="link"
      *ngIf="!basketService.atLeastOneSearchChosen() && !isOnHomePageOrBasket() && !isOnResults()">
      Skip to Recent Searches
    </a>
    <a
      class="sr-only sr-only-focusable btn btn-link"
      (click)="focusBasketButton()"
      role="link"
      (keydown.enter)="focusBasketButton()"
      i18n="@@resultsHeaderGoToBasket"
      tabindex="0"
      *ngIf="(basketService.atLeastOneSearchChosen() && !isOnHomePageOrBasket()) || isOnResults()">
      Skip to Basket
    </a>
    <div
      (click)="resetAndBackToSearch()"
      (keydown.enter)="resetAndBackToSearch()"
      tabindex="0"
      role="link"
      aria-label="Return to home">
      <img class="lightningLogoImg" alt="Logo - Return to Home" [src]="logoUrl" />
    </div>
  </div>
  <div class="header_bar_nav_section">
    <div
      class="nav-section-item"
      routerLink="/search"
      routerLinkActive="isActive"
      id="main-new-booking"
      aria-labelledby="main-new-booking"
      i18n="@@resultHeaderNewBooking"
      role="link"
      (keydown.enter)="redirect('/search'); this.searchService.reset()"
      (click)="redirect('/search'); this.searchService.reset()"
      tabindex="0">
      New Booking
    </div>
    <div
      [routerLinkActiveOptions]="{ exact: true }"
      class="nav-section-item"
      routerLink="/my-bookings"
      routerLinkActive="isActive"
      id="main-my-booking"
      aria-labelledby="main-my-booking"
      i18n="@@resultHeaderMyBooking"
      role="link"
      (keydown.enter)="redirect('/my-bookings'); this.searchService.reset()"
      (click)="redirect('/my-bookings'); this.searchService.reset()"
      tabindex="0">
      My Bookings
    </div>
    <div
      *ngIf="themeService.isLightningEnv && showBolt"
      class="nav-section-item"
      routerLink="/gmt/bolt/search-form"
      routerLinkActive="isActive"
      [routerLinkActiveOptions]="{ exact: true }"
      id="main-Bolt"
      aria-labelledby="main-Bolt"
      role="link"
      (keydown.enter)="redirect('/bolt')">
      <span i18n="@@resultHeaderDoortoDoor">Door-to-Door</span>
      <!-- <span class="beta-container" ngbTooltip="This is a new feature designed to get you from A to B quicker. If you find any issues or would like to propose an enhancement please report them in the usual way. Thank you."
      placement="bottom" i18n-ngbTooltip tooltipClass="beta-tooltip">BETA</span> -->
    </div>
    <div
      class="nav-section-item"
      routerLink="/gmt/get-us-there/search-form"
      routerLinkActive="isActive"
      *ngIf="showGetUsThere"
      [routerLinkActiveOptions]="{ exact: true }"
      id="main-GetUsThere"
      aria-labelledby="main-GetUsThere"
      i18n="@@resultHeaderDoortoDoorPlus"
      role="link"
      (keydown.enter)="redirect('/gmt/get-us-there')">
      Door-to-Door+
    </div>
    <!-- ********* -->
    <div class="nav-section-item" *ngIf="showUsefulInformationDropdown">
      <div
        id="Show-Useful-Information"
        title="Show Useful Information"
        i18n-title
        i18n-aria-label
        (click)="displayUsefulLinks()"
        (keydown.enter)="displayUsefulLinks()"
        tabindex="0"
        [attr.aria-expanded]="displayUsefulInformation"
        [attr.aria-label]="
          displayUsefulInformation ? 'Hide Useful Information' : 'Show  Useful Information'
        "
        role="button">
        <span i18n="@@UsefulInfo">Useful Information</span>
        <span *ngIf="!displayUsefulInformation"> <fa-icon [icon]="faChevronDown"></fa-icon></span>
        <span *ngIf="displayUsefulInformation"> <fa-icon [icon]="faChevronUp"></fa-icon></span>
      </div>
      <div *ngIf="displayUsefulInformation" class="useful-info-list-wrapper">
        <ul class="useful-list">
          <li class="useful-list-item selectable_item">
            <div
              aria-label="Contact"
              i18n-ngbTooltip
              (click)="openContactsUrl()"
              (keydown.enter)="openContactsUrl()"
              role="link"
              tabindex="0"
              i18n="@@Contact">
              Contact
            </div>
          </li>
          <li class="useful-list-item selectable_item">
            <div
              aria-label="Feedback and Support"
              i18n-ngbTooltip
              (click)="openFeedbackUrl()"
              (keydown.enter)="openFeedbackUrl()"
              role="link"
              tabindex="0"
              i18n="@@FeedbackPortal">
              Feedback and Support
            </div>
          </li>
          <li class="useful-list-item selectable_item">
            <div
              aria-label="User Guides"
              i18n-ngbTooltip
              (click)="openUserGuides()"
              (keydown.enter)="openUserGuides()"
              role="link"
              tabindex="0"
              i18n="@@UserGuides">
              User Guides
            </div>
          </li>
          <li class="useful-list-item selectable_item">
            <div
              aria-label="Price Match"
              i18n-ngbTooltip
              (click)="openPriceMatch()"
              (keydown.enter)="openPriceMatch()"
              role="link"
              tabindex="0"
              i18n="@@PriceMatch">
              Price Match
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- ******** -->
  </div>

  <div class="nav-disabled-policies" *ngIf="overrideTravelPolicy">
    <p
      id="main-OverrideTravelPolicy"
      aria-labelledby="main-OverrideTravelPolicy"
      i18n="@@MainAgentsOverrideTravelPolicy"
      tabindex="5">
      All Travel Policies Disabled
    </p>
  </div>

  <div class="header_bar_nav" *ngIf="showSupportEmail()">
    <button
      class="large-button info-button"
      *ngIf="userIsClient()"
      title="Support"
      id="support"
      aria-label="Support"
      (click)="openSupport()"
      tabindex="0">
      <i class="ico ico-info-circle"></i>
    </button>
    <button
      class="large-button support-email"
      *ngIf="!userIsClient()"
      title="Support"
      id="support"
      aria-label="Support"
      (click)="openSupport()"
      i18n="@@HeaderSupportModal"
      tabindex="0">
      Help
    </button>
    <span class="header_label" aria-hidden="true">Help</span>
  </div>

  <div class="header_bar_nav show_mobile" *ngIf="themeService.isLightningEnv">
    <div
      class="beamerTrigger"
      tabindex="0"
      role="button"
      title="What's New"
      i18n-title
      (click)="setBeamerIframeTitle()">
      <fa-icon
        aria-hidden="true"
        [icon]="faBell"
        class="fa-bell-icon"
        i18n="@@BeamerNotificationsButton"></fa-icon>
      <span class="sr-only">Whats new -&nbsp;</span>
      <!-- NEED TO FIGURE OUT HOW TO DISPLAY THE BADGE NUMBER -->
      <!-- <span class="badge rounded-pill badge-notification bg-danger">1</span> -->
    </div>
  </div>

  <div *ngIf="helpUrl" class="header_bar_nav">
    <a
      [href]="helpUrl"
      aria-label="Help"
      target="_blank"
      i18n-ngbTooltip
      title="Userguides, helpful info or to contact us. alt+h."
      class="header-buttons help"
      tabindex="0">
      ?
      <span id="aria-help-button" class="header-buttons logout" hidden i18n="@@AriaHelpButton">
        Help
      </span>
    </a>
  </div>
  <!--<div class="header_bar_nav">
      <a accesskey="w"
         href="https://travelctm-north.co.uk/DAC.php"
         aria-label="Accessibility Statement"
         target='_blank'
         i18n-ngbTooltip
         i18n-title
         i18n-aria-label
         title='Accessibility statement. alt+w.'
         class="header-buttons"
         tabindex="0">
         <img src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/wcag.png"
              class="wcag-icon"
              alt="Accessibility statement">
         <span id="aria-wcag-button"
               hidden
               i18n="@@WCAGButton">
            Accessibility Statement
         </span>
      </a>
   </div>-->
  <!-- <div class="header_bar_nav"
        *ngIf="logoutIcon">
      <button class="header-buttons logout"
              i18n-title
              i18n-name
              title='Log Out'
              name="Log Out"
              (click)='logout()'
              (keydown.enter)="logout()"
              role="button"
              aria-labelledby="aria-logout-button"
              tabindex="0">
         <span id="aria-logout-button"
               hidden
               aria-labelledby="aria-logout-button"
               i18n="@@AriaLogoutButton">Logout</span>
      </button>
      <span class="header_label" i18n="@@AriaLogoutButton"
            aria-hidden="true">Logout</span>
   </div> -->

  <!-- <div class="header_bar_nav show_mobile"
        *ngIf="userIcon">
      <button class="header-buttons user"
              id="Show-User-Options"
              aria-label="Show User Options"
              title='Show User Options'
              i18n-title
              i18n-aria-label
              (click)='displayUserData()'
              tabindex="0"
              [attr.aria-expanded]="display_user_data">
         <span id="aria-user-dropdown"
               hidden
               aria-labelledby="aria-user-dropdown"
               i18n="@@AriaUserDropdown">User information</span>
      </button>
      <span class="header_label" i18n="@@UserOptions"
            aria-hidden="true">User Options</span>
   </div> -->
  <div class="header_bar_nav user_navitem show_mobile" *ngIf="userIcon">
    <div
      id="Show-User-Options"
      (click)="displayUserData()"
      (keydown.enter)="displayUserData()"
      tabindex="0"
      [attr.aria-expanded]="display_user_data"
      role="button">
      <div class="circleRow">
        <div class="circle">
          <span>{{ name_initials }}</span>
        </div>
        <div class="user_fullname">{{ user_info?.name }}</div>
        <span class="visuallyhidden">-Show User Options</span>
        <div>
          <fa-icon [icon]="faChevronDown"></fa-icon>
        </div>
      </div>
    </div>
  </div>

  <!-- Admin/User functions dropdown -->
  <div class="user_dropdown_container" *ngIf="display_user_data">
    <div class="user_dropdown">
      <div class="user_dropdown_info">
        <p>{{ user_info.title }} {{ user_info?.name }}</p>
        <p>{{ user_info.email }}</p>
        <p><span i18n="@@UserAccountType">Account Type</span>: {{ user_info.account_type }}</p>
        <p *ngIf="live" i18n="@@BookingsAreLive">Bookings are live</p>
        <p *ngIf="!live" i18n="@@BookingsAreNotLive">Bookings are NOT live</p>
        <p class="profile_information_link">
          <a href="#/profile-information" i18n="@@UserProfileInformation">Profile Information</a>
        </p>
        <p
          class="languages-wrapper"
          id="Set-User-Language"
          title="Change Language"
          i18n-title
          i18n-aria-label
          (click)="toggleLanguagesState = !this.toggleLanguagesState"
          (keydown.enter)="toggleLanguagesState = !this.toggleLanguagesState"
          [attr.aria-label]="toggleLanguagesState ? 'Hide Languages' : 'Show Languages'"
          tabindex="0"
          [attr.aria-expanded]="toggleLanguagesState"
          role="button">
          <fa-icon [icon]="faLanguage"></fa-icon>
          <span i18n="@@UserLanguages">Languages</span>
          <span *ngIf="!toggleLanguagesState"> <fa-icon [icon]="faChevronDown"></fa-icon></span>
          <span *ngIf="toggleLanguagesState"> <fa-icon [icon]="faChevronUp"></fa-icon></span>
        </p>
        <div *ngIf="toggleLanguagesState" class="languages-list-wrapper">
          <p i18n="@@PleaseSelectYourPreferredLanguage">Please select your preferred language.</p>
          <select
            class="select-box select-box--border select-box--no-icon"
            *ngIf="availableLanguages && availableLanguages.length"
            (ngModelChange)="updateLanguage($event)"
            [(ngModel)]="selectedLanguage.id">
            <option *ngFor="let language of availableLanguages" [ngValue]="language?.id">
              {{ language?.name }}
            </option>
          </select>
        </div>
        <!-- ****** -->
        <p
          *ngIf="showUsefulInformationDropdown"
          class="uc-wrapper user_dropdown_item_booking"
          title="Useful Information"
          i18n-title
          i18n-aria-label
          (click)="toggleUsefulInfoState = !this.toggleUsefulInfoState"
          (keydown.enter)="toggleUsefulInfoState = !this.toggleUsefulInfoState"
          [attr.aria-label]="toggleUsefulInfoState ? 'Hide Useful Info' : 'Show Useful Info'"
          tabindex="0"
          [attr.aria-expanded]="toggleUsefulInfoState"
          role="button">
          <span i18n="@@UsefulInfo">Useful Information</span>
          <span *ngIf="!toggleUsefulInfoState"> <fa-icon [icon]="faChevronDown"></fa-icon></span>
          <span *ngIf="toggleUsefulInfoState"> <fa-icon [icon]="faChevronUp"></fa-icon></span>
        </p>
        <div *ngIf="toggleUsefulInfoState">
          <div
            class="selectable_item user_dropdown_item_booking"
            aria-label="Contact"
            i18n-ngbTooltip
            (click)="openContactsUrl()"
            (keydown.enter)="openContactsUrl()"
            role="link"
            tabindex="0"
            i18n="@@Contact">
            Contact
          </div>
          <div
            class="selectable_item user_dropdown_item_booking"
            aria-label="Feedback and Support"
            i18n-ngbTooltip
            (click)="openFeedbackUrl()"
            (keydown.enter)="openFeedbackUrl()"
            role="link"
            tabindex="0"
            i18n="@@FeedbackPortal">
            Feedback and Support
          </div>
          <div
            class="selectable_item user_dropdown_item_booking"
            aria-label="User Guides"
            i18n-ngbTooltip
            (click)="openUserGuides()"
            (keydown.enter)="openUserGuides()"
            role="link"
            tabindex="0"
            i18n="@@UserGuides">
            User Guides
          </div>
          <div
            class="selectable_item user_dropdown_item_booking"
            aria-label="Price Match"
            i18n-ngbTooltip
            (click)="openPriceMatch()"
            (keydown.enter)="openPriceMatch()"
            role="link"
            tabindex="0"
            i18n="@@PriceMatch">
            Price Match
          </div>
        </div>
      </div>
      <div
        *ngIf="userCarbonAllowance"
        class="user_dropdown_item selectable_item"
        target="_blank"
        role="button"
        tabindex="0"
        (click)="showCarbonAllowance = !showCarbonAllowance">
        <span i18n="@@UserCarbonAllowance">Carbon Allowance</span>
        <div class="carbon-container" *ngIf="showCarbonAllowance">
          <div class="flex-row-container flex-column-mobile">
            <div class="flex-column-container flex-column-container--left left-col">
              <p>{{ carbonPolicyHeader }}</p>
              <br />
              <p>
                <strong i18n="@@UserCarbonAllowanceRemaining">Remaining:</strong>
                {{ userCarbonAllowance.remainingCo2 }} Kg
              </p>
              <p>
                <strong i18n="@@UserCarbonAllowanceAllowance">Allowance:</strong>
                {{ userCarbonAllowance.totalCo2 }} Kg
              </p>
            </div>
            <div class="flex-column-container flex-column-container--left left-col">
              <p>
                <strong>{{ startOfPeriod }} - {{ endOfPeriod }}</strong>
              </p>
            </div>
            <!-- <span>{{userCarbonAllowance}}</span> -->
          </div>
        </div>
        <div class="carbon-arrow" *ngIf="showCarbonAllowance"></div>
      </div>
      <div
        *ngIf="showLanguages()"
        class="user_dropdown_item selectable_item"
        target="_blank"
        role="button"
        tabindex="0">
        <span i18n="@@UserDropdownLanguage">Language Selection</span>
        <app-language-selection></app-language-selection>
      </div>
      <div
        *ngIf="isUserAdmin"
        class="user_dropdown_item selectable_item"
        (click)="openAdminModal()"
        (keydown.enter)="openAdminModal()"
        (keydown.space)="openAdminModal()"
        target="_blank"
        tabindex="0"
        role="button"
        i18n="@@UserDropdownAdmin">
        User Admin
      </div>
      <div
        class="user_dropdown_item selectable_item"
        (click)="openBecomeUserModal()"
        (keydown.enter)="openBecomeUserModal()"
        role="menuitem"
        *ngIf="canBecomeUser && !isSupportAgent"
        tabindex="0"
        role="button"
        i18n="@@UserDropdownBecomeUser">
        Become User
      </div>
      <div
        class="user_dropdown_item selectable_item"
        (click)="endUserSession()"
        (keydown.enter)="endUserSession()"
        *ngIf="isSupportAgent"
        role="menuitem"
        tabindex="0"
        role="button"
        i18n="@@UserDropdownBackToAgencyUser">
        Back to Agency User
      </div>
      <div
        class="user_dropdown_item selectable_item user_dropdown_item_booking"
        (keydown.enter)="redirect('/search'); this.searchService.reset()"
        (click)="redirect('/search'); this.searchService.reset()"
        tabindex="0"
        role="button"
        i18n="@@AriaNewBookingButton">
        New Booking
      </div>
      <div
        class="user_dropdown_item selectable_item user_dropdown_item_booking"
        (keydown.enter)="redirect('/my-bookings'); this.searchService.reset()"
        (click)="redirect('/my-bookings'); this.searchService.reset()"
        role="menuitem"
        tabindex="0"
        role="button"
        i18n="@@AriaMyBookingsButton">
        My Bookings
      </div>
      <div
        *ngIf="themeService.isLightningEnv && showBolt"
        class="user_dropdown_item selectable_item user_dropdown_item_booking"
        (keydown.enter)="redirect('/gmt/bolt/search-form')"
        (click)="redirect('/gmt/bolt/search-form'); this.searchService.reset()"
        role="button"
        tabindex="0">
        <span i18n="@@AriaDoortoDoor">Door-to-Door</span>
        <!-- <span class="beta-container" ngbTooltip="This is a new feature designed to get you from A to B quicker. If you find any issues or would like to propose an enhancement please report them in the usual way. Thank you."
              placement="bottom" i18n-ngbTooltip>BETA</span> -->
      </div>
      <div
        class="user_dropdown_item selectable_item user_dropdown_item_booking"
        (keydown.enter)="redirect('/gmt/get-us-there')"
        (click)="redirect('/gmt/get-us-there'); this.searchService.reset()"
        role="button"
        *ngIf="showGetUsThere"
        tabindex="0"
        i18n="@@AriaDoortoDoorPlus">
        Door-to-Door+
      </div>
      <div
        class="user_dropdown_item selectable_item"
        (click)="logout()"
        (keydown.enter)="logout()"
        *ngIf="logoutIcon"
        role="menuitem"
        tabindex="0"
        role="button"
        i18n="@@AriaLogoutButton">
        Log Out
      </div>
    </div>
  </div>
</div>
<div
  class="become-user-email-container"
  *ngIf="isSupportAgent && user_info.email"
  [ngClass]="{ 'become-user-padding': !blank && !isOnHomePage() }">
  <span i18n="@@BecomeUserActive">Become user active</span>: {{ user_info.email }}
</div>
<app-sub-header-bar
  *ngIf="!blank && !isSubHidden"
  [page]="page"
  [resultsType]="resultsType"
  [basketItemTypes]="basketItemTypes"></app-sub-header-bar>
