import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';
import { FlightItineraryWithExtensions, RuleActionSummaryInterface } from '@sabstravtech/obtservices/base';
import { faSuitcaseRolling, faSuitcase, faUtensils, faWifi, faCalendarDays, faCoins } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-flight-upsell-dialog',
  templateUrl: './flight-upsell-dialog.component.html',
  styleUrl: './flight-upsell-dialog.component.scss'
})

export class FlightUpsellDialogComponent {
  selectedFare: FlightItineraryWithExtensions = null;
  upsellFares: FlightItineraryWithExtensions[] = [];
  isDualFlight: boolean = false;
  isDualFlightReturn: boolean = false;
  isReturnFlight: boolean = false;
  faSuitcaseRolling = faSuitcaseRolling;
  faSuitcase = faSuitcase;
  faUtensils = faUtensils;
  faWifi = faWifi;
  faCalendarDays = faCalendarDays;
  faCoins = faCoins;

  constructor(
    public modal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.isReturnFlight = this.selectedFare.inboundFlights?.length > 0;
  }

  addToBasket(fare: FlightItineraryWithExtensions): void {
    this.modal.close(fare);
  }
}

LightningModalTypes.FlightUpsellDialogComponent.component = FlightUpsellDialogComponent;
