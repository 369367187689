<div class="fare-rules"
     cdkTrapFocus
     aria-live="assertive">
  <i class="fare-rules_exit-icon ico-cancel"
     (click)="close()"
     (keydown.enter)="close()"
     role="button"
     aria-label="close"
     tabindex="0"></i>
  <div class="fare-rules_item" *ngIf="!selectedFare || selectedFare === EurostarClassEnum.STANDARD">
    <div class="fare-rules_item-header fare-rules_item-header--standard"><span i18n="@@fareRulesModStandard"
            #standardHeader
            tabindex="0"
            id="standardHeader">Eurostar Standard</span></div>
    <div class="fare-rules_item-content">
      <ul class="fare-rules_content-list">
        <li class="fare-rules_content-list-item"
            *ngFor="let rule of standard.rules">
          <span>{{rule}}</span>
        </li>
      </ul>
    </div>
    <div class="fare-rules_item-footer">
      <p>
        <span class="light-fat"
              i18n="@@fareRulesModFareConditions">Fare conditions:</span>
        {{standard.fareConditions}}
      </p>
    </div>
  </div>
  <div class="fare-rules_item" *ngIf="!selectedFare || selectedFare === EurostarClassEnum.STANDARD_PREMIER">
    <div class="fare-rules_item-header fare-rules_item-header--standard-premier"><span
            i18n="@@fareRulesModstandardPremier">Eurostar Plus</span></div>
    <div class="fare-rules_item-content">
      <ul class="fare-rules_content-list">
        <li class="fare-rules_content-list-item"
            *ngFor="let rule of standardPremier.rules">
          <span>{{rule}}</span>
        </li>
      </ul>
    </div>
    <div class="fare-rules_item-footer">
      <p>
        <span class="light-fat"
              i18n="@@fareRulesModFareConditions2">Fare conditions:</span>
        {{standardPremier.fareConditions}}
      </p>
    </div>
  </div>
  <div class="fare-rules_item" *ngIf="!selectedFare || selectedFare === EurostarClassEnum.BUSINESS_PREMIER">
    <div class="fare-rules_item-header fare-rules_item-header--business-premier"><span
            i18n="@@fareRulesModBusinessPremier">Eurostar Premier</span></div>
    <div class="fare-rules_item-content">
      <ul class="fare-rules_content-list">
        <li class="fare-rules_content-list-item"
            *ngFor="let rule of businessPremier.rules">
          <span>{{rule}}</span>
        </li>
      </ul>
    </div>
    <div class="fare-rules_item-footer">
      <p>
        <span class="light-fat"
              i18n="@@fareRulesModFareConditions3">Fare conditions:</span>
        {{businessPremier.fareConditions}}
      </p>
    </div>
  </div>
</div>