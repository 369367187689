import { Component, Input } from '@angular/core';
import { FlightJourney } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-mixed-cabin-tooltip',
  templateUrl: './mixed-cabin-tooltip.component.html',
  styleUrl: './mixed-cabin-tooltip.component.scss'
})
export class MixedCabinTooltipComponent {
  @Input() flights: FlightJourney[] = [];
  @Input() inboundFlights: FlightJourney[] = [];
}
