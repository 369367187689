import { Environment } from '@sabstravtech/obtservices/angular';

class EnvironmentImpl extends Environment {
    App_check_time: number = 60 * 60 * 1000;
    production = true;
    bypass_logon = false;
    disable_console = true;
    hostBaseUrl = '';
    authURL = '/auth/';
    requestURL = '/rqh/graphql';
    authRefreshTimeout = 14.5 * 60; // time in seconds  between auto refreshing auth
    maxFlightResults = 0; // The maximum number of flights to return in a search, useful for debuging.
    timeout = 60 * 60; // set login timeout if 0/false thany will never log you out
    httpCallTimeout = 300 * 1000; // in ms
    maxNumberRecentSearches = 10;
    useSessionStorageForRecentSearches = false;
    AppNotificationBaseUrl = '';
    tagMap = {
        'CTM Partners - Business Ready': 'https://images.sabscorp.com/images/icons/covidmask.png',
        'Green Hotel': 'https://images.sabscorp.com/images/icons/ctm_green_choice.png',
        'Electric Car Charging Point': 'https://images.sabscorp.com/images/icons/electric_car.png'
    };
    numberHotelToPreload = Number.MAX_SAFE_INTEGER;
    groupMax = 0;
    maxFlightsToShow = 8;
    obt = 'lightning';
    auth0Domain = 'ctmuk-dev.eu.auth0.com';
    auth0ClientId = 'N35iGniO8d4sF4abWMyT3oQfsBRq0rTW';
    redirectUrlLu = 'https://ctmlightning.co.uk';
    redirectUrlScion = 'https://dev.sabsenterprise.com'; // this has to be changed, just POC
    UserCentricsUrl = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
    UserCentricsID = 'kKM6FnahY4LiKj';
    PendoApiKey = 'e2e06d74-193f-48ca-57ae-9367dadcd9b3';


}

export const environment = new EnvironmentImpl();
