<div cdkTrapFocus aria-live="assertive">
  <div class="modal-header">
    <h3
      class="modal-title warning-title"
      i18n="@@modalCustomWarningOccurredTitle"
      id="modal-header">
      <img class="exclame_image" [src]="exclamationURL" alt="Warning" i18n-alt tabindex="0" />
      Warning
    </h3>
    <button
      type="button"
      class="close"
      attr.aria-label="Warning Close"
      ngbAutoFocus
      i18n-aria-label
      (click)="OK()">
      <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ warningMessage }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="modal_button_okay" (click)="OK()" i18n="@@modalCustomErrorOK">
      Ok
    </button>
  </div>
</div>
