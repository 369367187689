import { Pipe, PipeTransform } from '@angular/core';
import { Vendor } from '@sabstravtech/obtservices/base';
import {
  EnterpriseSearchService, ServiceType
} from '@sabstravtech/obtservices/angular';

@Pipe({
  name: 'carVendorFullName',
  pure: true,
})
export class CarVendorNamePipe implements PipeTransform {
  constructor(public searchService: EnterpriseSearchService) {}

  transform(code: string): string {
    const vendor = (this.searchService.searches[ServiceType.Car].vendors as Vendor[]).find((v: Vendor) => v.code.toUpperCase() === code.toUpperCase());
    return vendor ? vendor.name : code;
  }
}
