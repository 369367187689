import { ArraySortPipe } from '../../../startup/pipes/flights-office-list.pipe';
import {
  Component,
  HostListener,
  QueryList,
  ViewChild,
  ViewChildren,
  OnInit,
  Directive,
  Input
} from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';
import { FlightPipe } from '../../../startup/pipes/flight-pipe';
import { NgbInputDatepicker, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, of, BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';
import { TranslateService } from '@ngx-translate/core';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';
import {
  LocationTypes,
  ServiceProvider,
  HotelEnterpriseSearchInterface,
  LocationDetails,
  Country,
} from '@sabstravtech/obtservices/base';
import {
  ServiceType,
  EnterpriseSearchService,
  UserService,
} from '@sabstravtech/obtservices/angular';
import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import {
  HotelSearchFormConfiguration,
  UnitType
} from '../../../vendor/interfaces/hotel-search-form-configuration';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[roomNoValidate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: RoomNoValidateDirective,
      multi: true
    }
  ]
})
export class RoomNoValidateDirective implements Validator {
  @Input('roomNoValidate') maxRooms: number;

  LocationTypes: typeof LocationTypes = LocationTypes;

  validate(control: AbstractControl): { [key: string]: any; } | null {
    return this.maxRooms && Number(this.maxRooms) < Number(control.value)
      ? { maxRooms: { value: control.value } }
      : null;
  }
}

@Component({
  selector: 'app-hotel-search',
  templateUrl: './hotel-search.component.html',
  styleUrls: ['./hotel-search.component.scss']
})
export class HotelSearchComponent extends BaseComponent implements OnInit {
  @ViewChildren(NgbInputDatepicker) datepickerList: QueryList<NgbInputDatepicker>;
  public declarations: ArraySortPipe[];
  public focus$ = new Subject<string>();
  public focus2$ = new Subject<string>();

  public focus4$ = new Subject<string>();
  public showOptions: boolean = true;
  public tempAirArray: Observable<string[]> = of([]);
  public tempHotelArray: Observable<string[]> = of([]);
  public tempRailArray: Observable<string[]> = of([]);

  public LocationTypes: typeof LocationTypes = LocationTypes;
  @ViewChild('instance') instance: NgbTypeahead;
  searchParams: HotelEnterpriseSearchInterface;
  isLocationLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public trainIcon: boolean = true;

  public titleCountry: string = 'Country';
  officeClick$: Subject<string> = new Subject();
  loadingOffices: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  officesList: LocationDetails[] = [];
  userDistanceUnit: string;
  confermaIsEnabled = false;
  country: Country;
  crownSet: boolean = false;
  componentInitialized = false;
  constructor(
    public userService: UserService,
    public deviceDetector: DeviceDetector,
    public searchService: EnterpriseSearchService,
    public flightPipe: FlightPipe,
    public title: Title,
    public translateService: TranslateService
  ) {
    super(title, translateService);
  }

  ngOnInit(): void {
    this.crownSet = this.userService.crownIsSet();

    this.showOptions = !this.userService.isUserFavoriteSet(
      LightningUserFavorurite.hideHotelSearchOptions
    );
    this.searchParams = this.searchService.searches[ServiceType.Hotel];

    const hotelSearchFormConfiguration =
      this.userService.getUserFavoriteObject<HotelSearchFormConfiguration>(
        LightningUserFavorurite.HotelSearchFormConfiguration
      );

    this.userDistanceUnit = hotelSearchFormConfiguration?.distanceUnit?.unit || UnitType.Miles;
    this.searchParams.distance = +(hotelSearchFormConfiguration?.distanceValue?.value) || 5;
    this.confermaIsEnabled = this.userService.userHasServiceProvider(
      ServiceType.Hotel,
      ServiceProvider.Confirma
    );

    this.orderByLocationType();
    this.getOfficeNames();

    this.subscribe(this.userService.newInvokedUser, newInvokedUser => {
      if (this.componentInitialized) { //don't clear the office when the component has just initialized
        this.searchParams.office = null;  // clear the office incase we get an incorrect office
        this.getOfficeNames();
      }
      this.componentInitialized = true;
    });

  }

  @HostListener('document:click', ['$event'])
  public onClick(event) {
    // Helpers.closeOpenCalendars(this.datepickerList, event);
  }

  formatter = (x: { name: string; countryCode?: string; }) => `${x.name}`;
  formatter_rail_air = (x: { destination: string; }) => this.flightPipe.transform(x.destination);
  postCodeFormatter = (x: { name: string; }) => `${x.name}`;

  chainFormatter = (x: any) => `${x.name}`;

  // chainFormatter = (name: string) => name;

  /**
   * @description - On Model Change function - check if we are switching to offices and don't have any, if so attempt to load the offices
   * @param $event - the new model value
   */
  hotelLocationChange($event: string): void {
    console.log('+++ Hotel Location Change: ', $event, ' +++');
    this.searchParams.location = null;
  }

  /**
   *	@desc - reset the location - this is called when changing the country/postcode - so we don't get mixed up at search time
   **/
  resetLocation($event: any): void {
    console.log('location reset');
    // this.searchService.hotel.location = null;
  }

  /**
    @desc - reset the postcode, this is called when changing the location - we only have one postcode or loc - so as not to confuse the search
  **/
  resetPostcode($event: any): void {
    // this.searchService.hotel.postcode = null;
  }

  // Search location in city
  searchLocations = (text$: Observable<string>): Observable<any[]> => {
    let isConfirma = this.userService.userHasServiceProvider(
      ServiceType.Hotel,
      ServiceProvider.Confirma
    );
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return debouncedText$.pipe(
      switchMap(
        (term: string): Observable<any[]> =>
          term.length <= 2
            ? of([])
            : this.searchService.getCityLocations(
              term,
              this.searchParams.country.cCode,
              this.isLocationLoading,
              isConfirma
            )
      )
    );
  };

  searchPostCode = (text$: Observable<string>): Observable<any[]> => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return debouncedText$.pipe(
      switchMap(
        (term: string): Observable<any[]> =>
          term.length < 2
            ? of([])
            : this.searchService.getPostcodeList(term, this.searchParams.country.cCode)
      )
    );
  };

  // getOffices = (text$: Observable<string>): Observable<any[]> => {
  //   const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
  //   return debouncedText$
  //     .pipe(
  //       switchMap(
  //         (term: string): Observable<any[]> =>
  //           term.length <= 2
  //             ? of([]) : (this.searchService.getOfficeLocations(term))
  //       )
  //     );
  // };

  searchRailLocations = (text$: Observable<string>): Observable<any[]> => of([]);
  // text$.pipe(
  //   debounceTime(200),
  //   distinctUntilChanged(),
  //   merge(this.focus2$),
  //   switchMap(
  //     (term: string): Observable<any[]> =>
  //       term.length <= 2
  //         ? this.tempRailArray
  //         : (this.tempRailArray = this.searchService.getDestinationResults(null, term, 'filternumbers', 11))
  //   )
  // );

  searchChains = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return debouncedText$.pipe(
      switchMap(
        (term: string): Observable<any[]> =>
          term.length <= 2 ? of([]) : this.searchService.getHotelChain(term)
      )
    );
  };

  getOfficeNames() {
    this.subscribe(this.searchService.getOfficeLocationsNoUserAddresses(), (offices: LocationDetails[]) => {
      this.officesList = offices;
    });
  }

  limitRoomCountPerTraveller(): number {
    let noOfTravellers = this.searchService.getCurrentNoOfTravellers();
    if (this.searchParams.max_no_of_rooms >= noOfTravellers) {
      return noOfTravellers;
    } else {
      return this.searchParams.max_no_of_rooms;
    }
  }

  changeRooms(): void {
    // this.searchService.hotel.rooms_error = false;
  }

  ensureElementIsScrolledTo(event) {
    try {
      const typeAheadList = event.target.nextElementSibling;
      const activeButton = typeAheadList.getElementsByClassName('active')[0];
      if (
        activeButton.offsetTop + activeButton.clientHeight >
        typeAheadList.clientHeight + typeAheadList.scrollTop
      ) {
        typeAheadList.scrollTop =
          activeButton.offsetTop + activeButton.clientHeight - typeAheadList.clientHeight;
      } else if (activeButton.offsetTop < typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop;
      }
    } catch (e) {
      // tslint:disable-next-line: quotemark
      console.log("Couldn't find elements to scroll");
    }
  }

  countryChange(event: any) {
    this.searchParams.country = event;
  }

  orderByLocationType(): void {
    this.searchParams.location_types.next([
      LocationTypes.City,
      LocationTypes.Office,
      LocationTypes.Airport,
      LocationTypes.TrainStation,
      LocationTypes.Shortlist
    ]);
  }

  compareOffice(optionOne: LocationDetails, optionTwo: LocationDetails): boolean {
    if (optionOne && optionTwo) {
      return optionOne.id === optionTwo.id;
    }
  }
  forcePriority(event: Event): void {
    if (event?.type) {
      if (event.type === LocationTypes.Postcode) {
        this.searchParams.location = null;
      } else if (event.type === LocationTypes.City) {
        this.searchParams.postcode = null;
      }
    }
  }

}

