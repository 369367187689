import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlightItineraryWithExtensions, FlightEnterpriseSearchInterface, FlightSearchType, FlightDirectionEnum, RuleActionSummaryInterface, RecordRuleAction, RuleActionExtension } from '@sabstravtech/obtservices/base';
import { 
  EnterpriseSearchService, 
  ServiceType, 
  FlightJourney, 
  UserService, 
  FlightItinerary,
  BaggageType,
  HelperRoutines, 
  DataArgs,
  InputFlightJourney,
  RouteHappyResults } from '@sabstravtech/obtservices/angular';
import { AncillaryStatus, AncillaryTicket, AncillaryType } from '../../../../../vendor/enum/ancillary-status.enum';
import { TransformedFareAncillaries } from '../../../../../vendor/interfaces/flight-interfaces';
import _ from 'lodash';

@Component({
  selector: 'app-fare-section',
  templateUrl: './fare-section.component.html',
  styleUrl: './fare-section.component.scss'
})

export class FareSectionComponent {
  @Input() fare: FlightItineraryWithExtensions;
  @Input() title: string = ''; 
  @Input() isDualFlight: boolean = false;
  @Input() isDualFlightReturn: boolean = false;
  @Input() isUpsell: boolean = false;
  @Output() selectFare: EventEmitter<FlightItineraryWithExtensions> = new EventEmitter();
  ServiceType: typeof ServiceType = ServiceType;
  lastOutboundFlightJourney: FlightJourney;
  lastInboundFlightJourney: FlightJourney;

  searchParams: FlightEnterpriseSearchInterface;
  isAerLingus: boolean = false;
  isMultiCity: boolean = false;
  isReturnFlight: boolean = false;
  travelRules: RuleActionExtension = null;

  fareAncillaries: AncillaryStatus[][] = [];
  transformedFareAncillaries: TransformedFareAncillaries[] = [];
  routeHappyResults: RouteHappyResults;
  routeReturnHappyResults: RouteHappyResults;
  dualOutBound: FlightItinerary = null;
  constructor(
    public searchService: EnterpriseSearchService,
    public userService: UserService,
    private helpers: HelperRoutines
  ) {}

  ngOnInit(): void {
    if (this.fare.outboundFlights[0]?.marketingCarrier === 'EI') {
      this.isAerLingus = true;
    }
    this.searchParams = this.searchService.searches[ServiceType.Flight];
    this.isMultiCity = this.searchParams.chosenSearchType === FlightSearchType.multiCity;
    this.isReturnFlight = this.fare.inboundFlights?.length > 0;
    this.dualOutBound = this.searchParams.selectedFlight?.value;
    this.travelRules = this.getTravellerRules(this.fare);
    this.getLastFlightJourney();
    this.getAncillaryData();
    this.getRouteHappy();
    if (this.isReturnFlight) {
      this.getRouteHappy(true);
    }
  }

  getLastFlightJourney() {
    if (this.fare.inboundFlights?.length) {
      this.lastInboundFlightJourney = this.fare.inboundFlights[this.fare.inboundFlights.length - 1];
    }
    if (this.fare.outboundFlights?.length) {
      this.lastOutboundFlightJourney = this.fare.outboundFlights[this.fare.outboundFlights.length - 1];
    }
  }

  getAncillaryData() {
    const ancillaryTypes = [AncillaryType.Refund, AncillaryType.Changes];
    let ancillaries: AncillaryStatus[] = [];
    ancillaryTypes.forEach((type:AncillaryType) => {
      ancillaries.push(this.getAncillaryType(type, this.fare));
    });

    this.fareAncillaries.push(ancillaries);
    this.transformedFareAncillaries = this.fareAncillaries.map(originalObject => {
      return {
        refund: originalObject[0],
        changes: originalObject[1],
        hangBaggage: null, // don't need for upsell flight
        holdBaggage: null, // don't need for upsell flight
      };
    });
  }

  getAncillaryType(ancillary: AncillaryType, fare: FlightItinerary): AncillaryStatus {
    switch (ancillary) {       
      case AncillaryType.Refund:
        if (this.isAerLingus) {
          return AncillaryStatus.Unknown;
        }
        const zeroFeeCancel = fare.fareConditions?.cancellation?.some(x => x.amount === 0);
        const chargeableCancel = fare.fareConditions?.cancellation?.some(x => x.amount > 0);
        const refunable =
          fare.disclosures?.findIndex(a => a !== null && a === AncillaryTicket.RefundableTicket) !== -1 ||
          fare.additional?.refundable;
        if (zeroFeeCancel || chargeableCancel || refunable) {
          if (zeroFeeCancel || refunable || chargeableCancel) {
            if (chargeableCancel) {
              return AncillaryStatus.Chargeable;
            }

            if (zeroFeeCancel || refunable) {
              return AncillaryStatus.Included;
            }
          }
        }
        break;
      case AncillaryType.Changes:
        if (this.isAerLingus) {
          return AncillaryStatus.Unknown;
        }
        const zeroFeeChange = fare.fareConditions?.change?.some(x => x.amount === 0);
        const chargeableChange = fare.fareConditions?.change?.some(x => x.amount > 0);
        const changeable =
          fare.disclosures?.findIndex(a => a !== null && a === AncillaryTicket.ChangeableTicket) !== -1;
        if (zeroFeeChange || changeable || chargeableChange) {
          if (chargeableChange) {
            return AncillaryStatus.Chargeable;
          }

          if (zeroFeeChange || changeable) {
            return AncillaryStatus.Included;
          }
        }
        break;
    }
    return AncillaryStatus.Excluded;
  }

  getRouteHappy(isReturn: boolean = false) {
    const journeys = _.clone(isReturn ? this.fare.inboundFlights : this.fare.outboundFlights);

    journeys.forEach((journey) => {
      const unwantedProps = [ //Props that are on FlightJourney but not InputFlightJourney
        "journeyLegs",
        "co2PerItem",
        "co2PerPassenger",
        "journeyHash",
        "safRating",
        "segmentGroup"
      ];
      unwantedProps.forEach((unwantedProp) => {
        delete journey[unwantedProp];
      });
    });

    let dataArgs: DataArgs = {
      itineraries: [
        {
          journeys: journeys as InputFlightJourney[]
        }
      ],
      source: this.fare.source
    };

    this.searchService.getRouteHappy(dataArgs, this.fare.total.currency).subscribe((data: RouteHappyResults) => {
      if (isReturn) {
        this.routeReturnHappyResults = data;
      } else {
        this.routeHappyResults = data;
      }
    });
  }

  getTravellerRules(flight: FlightItineraryWithExtensions): RuleActionExtension {
    return {
      unavailable: flight.unavailable,
      unavailableMessage: flight.unavailableMessage,
      requiresReasonKeys: flight.requiresReasonKeys,
      requiresReasonMessages: flight.requiresReasonMessages
    };
  }

  getFareRules(flight: FlightItinerary): void {
    const { rules, flights } = this.helpers.makeFareRules(
      flight,
      FlightDirectionEnum.Outbound,
      this.isMultiCity
    );
    let inboundRules = null;
    let inboundFlights = null;
    if (this.isReturnFlight) {
      let inboundFareRulesObject = this.helpers.makeFareRules(flight, FlightDirectionEnum.Inbound);
      inboundRules = inboundFareRulesObject.rules;
      inboundFlights = inboundFareRulesObject.flights;
    }
    const flightDetail: FlightItinerary = this.isMultiCity || this.isReturnFlight ? flight : null;
    this.searchParams.openFlightFareRules(
      rules,
      flights,
      flight.sourceId,
      { centered: true, size: 'xl' },
      null,
      flightDetail,
      inboundRules,
      inboundFlights
    );
  }

  addToBasket(fare: FlightItineraryWithExtensions): void {
    this.selectFare.emit(fare);
  }
}