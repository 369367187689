import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { EurostarClassEnum } from '@sabstravtech/obtservices/base';
@Component({
  selector: 'app-fare-rules-modal',
  templateUrl: './fare-rules-modal.component.html',
  styleUrls: ['./fare-rules-modal.component.scss']
})
export class ModalFareRulesComponent implements OnInit {
  @ViewChild('standardHeader') standardHeader!: ElementRef;
  readonly standard = {
    rules: [
      'Exchange with no fee up to 1 hour pre-departure',
      'Refundable up to 7 days pre-departure for €25/£25/$40',
      '2 pieces of luggage + 1 small daypack/handbag',
      'Buy drinks, meals and snacks on board',
      'Free onboard wi-fi',
      'UK & EU plug sockets'
    ],
    // tslint:disable-next-line:max-line-length
    fareConditions: `You can change the date or time of your journey any time before departure by paying a fee and any difference in fare. These tickets are non-refundable.`
  };

  readonly standardPremier = {
    rules: [
      'Exchange with no fee up to 1 hour pre-departure',
      'Refundable up to 7 days pre-departure for €25/£25/$40',
      'A delicious light meal and drinks served at your seat',
      '2 pieces of luggage + 1 small daypack/handbag',
      'Extra spacious seats',
      'Free onboard wi-fi',
      'UK & EU plug sockets'
    ],
    // tslint:disable-next-line:max-line-length
    fareConditions: `You can change the date or time of your journey any time before departure by paying a fee and any difference in fare. These tickets are non-refundable.`
  };

  readonly businessPremier = {
    rules: [
      'Exchangeable and refundable with no fee up to 48 hours after departure',
      'Same-day boarding guarantee',
      'Exclusive lounge access in London, Paris and Brussels',
      'Priority ticket gates',
      'Extra spacious seats',
      'At-seat fine dining and drinks served by your dedicated on-board team',
      '3 pieces of luggage + 1 small daypack/handbag',
      'Free onboard wi-fi',
      'UK + EU plug sockets'
    ],
    fareConditions: `You can change your tickets free of charge up to 60 days after your planned departure.`
  };

  selectedFare: EurostarClassEnum = null;
  EurostarClassEnum = EurostarClassEnum;
  
  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.standardHeader) {
      this.standardHeader.nativeElement.focus();
    }
  }

  close(): void {
    this.modal.close();
    // Then focus back to the fare bucket
    if (document.getElementById('fareRulesStand')) {
      document.getElementById('fareRulesStand').focus();
    } else if (document.getElementById('fareRulesStandPrem')) {
      document.getElementById('fareRulesStandPrem').focus();
    } else if (document.getElementById('fareRulesBus')) {
      document.getElementById('fareRulesBus').focus();
    } else if (document.getElementById('fare-rules-icon')) {
      document.getElementById('fare-rules-icon').focus();
    }
  }
}

LightningModalTypes.ModalFareRulesComponent.component = ModalFareRulesComponent;
