import {
  Component,
  ViewEncapsulation,
  OnInit,
  EventEmitter,
  Input,
  OnDestroy
} from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { LightningModalTypes } from '../vendor/classes/modal-types.enum';
import {
  Language,
  DepartureBoardService,
  UserService,
  EnterpriseSearchService,
  ServiceType,
  EnterpriseBasketService,
  HelperRoutines,
  WithSubscriptionComponent,
  Office,
  CarHireVehicleAvailibility,
  LogonService
} from '@sabstravtech/obtservices/angular';
import { BookableBasket, LogoutEnum } from '@sabstravtech/obtservices/base';
import moment from 'moment';
declare var hex_hmac_md5: any;
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
@Component({
  templateUrl: './modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .dark-modal .modal-content {
        background-color: #292b2c;
        color: white;
      }
      .dark-modal .close {
        color: white;
      }
    `
  ]
})
export class ModalComponent {
  closeResult: string;

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) { }
}
LightningModalTypes.ModalComponent.component = ModalComponent;
@Component({
  templateUrl: './modal-user-error.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ModalUserErrorComponent {
  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  public authError = 'Authorisation Error';
}
LightningModalTypes.ModalUserErrorComponent.component = ModalUserErrorComponent;

@Component({
  templateUrl: './modal-error.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ModalErrorComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) { }
  public error = 'Unknown Error';
  public miError = false;
  public email = '';
  public number = '';

  ngOnInit() { }
}

LightningModalTypes.ModalErrorComponent.component = ModalErrorComponent;

@Component({
  templateUrl: './modal-success.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ModalSuccessComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) { }
  public success = 'Success';

  ngOnInit() { }
}

LightningModalTypes.ModalSuccessComponent.component = ModalSuccessComponent;

@Component({
  templateUrl: './modal-network-error.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ModalNetworkErrorComponent extends WithSubscriptionComponent implements OnInit {
  public error = null;
  public errorMessage = 'Unknown Error';
  public duplicateBooking = false;
  public paymentCardError = false;
  public liveRailError = false;
  public hotelCancelError = false;
  public unConfirmedBookingError = false;
  public titleNotSupportedError = false;
  public pureErrorMessageFromBE = false;
  public miErrorMessage = false;
  public errorLevel = 'Error';
  supportNumber = '0121 2345555';
  supportEmail = 'support@travelctm.com';
  bookableBasket: BookableBasket = null;
  isUISupportEmail: boolean = false;
  isUISupportPhone: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    public userService: UserService,
    public searchService: EnterpriseSearchService,
    private readonly router: Router,
    public basketService: EnterpriseBasketService,
    public helpers: HelperRoutines
  ) {
    super();
    this.bookableBasket = new BookableBasket(basketService, helpers);
  }

  ngOnInit() {
    console.log(this.error);
    if (typeof this.error === 'object') {
      if (this.error.message === 'This item already exists in this basket') {
        this.duplicateBooking = true;
        this.errorMessage = this.error.message;
      } else if (this.error.message === "Cannot read property 'trainServices' of undefined") {
        this.liveRailError = true;
        this.errorMessage =
          'The journey you have selected is not a direct route, please check the stations and try again';
      } else if (this.error?.message.includes('Payment failed for basket item ID')) {
        this.paymentCardError = true;
      } else if (
        this.error?.message.includes(
          'Hotel Get Booking Error: Cancellation was not successful, please try again or contact a support agent'
        )
      ) {
        this.hotelCancelError = true;
      } else if (this.error?.message.includes('Booking Unconfirmed')) {
        this.unConfirmedBookingError = true;
      } else if (this.error?.message.includes('Title not supported')) {
        this.titleNotSupportedError = true;
      } else if (this.error?.message.includes('The value entered for')) {
        this.miErrorMessage = true;
        this.errorMessage = this.error.message;
      } else if (this.error?.extensions?.code === 'FRIENDLY_ERROR') {
        this.pureErrorMessageFromBE = true;
        this.errorMessage = this.error.message;
        this.errorLevel = this.error?.extensions?.errorLevel;
      } else {
        this.errorMessage = this.error.message;
      }
    } else {
      this.errorMessage = this.error;
    }
    this.findSupportEmail();
  }

  findSupportEmail() {
    const routerUrl = this.router.url;
    const serviceType = this.findserviceType(routerUrl);
    const userSupportEmail = this.userService.getServiceSupportContactInfo(serviceType);
    this.isUISupportEmail = userSupportEmail?.enable && !!userSupportEmail.email;
    if (this.isUISupportEmail) {
      this.supportEmail = userSupportEmail.email;
    }
    this.isUISupportPhone = userSupportEmail?.enable && !!userSupportEmail.phone;
    if (this.isUISupportPhone) {
      this.supportNumber = userSupportEmail.phone;
    }
  }

  findserviceType(routerUrl: string): ServiceType {
    const searchObj = this.searchService.search_objects;
    const searchValues = Object.values(searchObj);
    switch (true) {
      case routerUrl === searchValues[0].searchUrl:
        return searchValues.find(service => service.chosen)?.type;
      case routerUrl.includes('results'):
        return searchValues.find(service => service.resultUrl === routerUrl)?.type;
      case routerUrl === '/live-rail':
        return ServiceType.Rail;
      case routerUrl.includes('itinerary'):
        const basketId = this.basketService.basketChangeEmitter.value;
        this.bookableBasket.loadBasket(basketId);
        const currentBasket = this.bookableBasket.basket.value;
        return currentBasket.basketItems.length === 1
          ? currentBasket.basketItems[0].service.type
          : null;
      default:
        return null;
    }
  }

  addNewCard() {
    window.location.reload();
    this.activeModal.close();
  }
}

LightningModalTypes.ModalNetWorkErrorComponent.component = ModalNetworkErrorComponent;
@Component({
  templateUrl: './modal-search-error.component.html'
})
export class ModalSearchErrorComponent {
  constructor(public activeModal: NgbActiveModal) { }

  public error = 'This Needs to be set';
}
LightningModalTypes.ModalSearchErrorComponent.component = ModalSearchErrorComponent;

@Component({
  templateUrl: './modal-search-timeout.component.html'
})
export class ModalSearchTimeoutComponent {
  constructor(public activeModal: NgbActiveModal) { }
  public closeModal = null;
}
LightningModalTypes.ModalSearchTimeoutComponent.component = ModalSearchTimeoutComponent;

/*
   ===================== Confirm Logout ======================
*/

@Component({
  templateUrl: './modal-confirm.component.html'
})
export class ModalConfirmComponent {
  private modal: NgbModalRef;
  constructor(public readonly activeModal: NgbActiveModal, public translator: TranslateService) { }

  public btnOkText = this.translator.instant('Ok');
  public btnCancelText = this.translator.instant('No');
  public message = this.translator.instant(
    'Please note: Start again will reset all current results and baskets.'
  );
  public message_header = this.translator.instant('Confirmation needed');
}
LightningModalTypes.ModalConfirmComponent.component = ModalConfirmComponent;

@Component({
  templateUrl: './modal-traveller-error.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalTravellerErrorComponent {
  constructor(public activeModal: NgbActiveModal) { }

  public error = 'An Error Occured!';
}
LightningModalTypes.ModalTravellerErrorComponent.component = ModalTravellerErrorComponent;

@Component({
  templateUrl: './modal-confirm-card.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalConfirmCardComponent {
  constructor(public activeModal: NgbActiveModal) { }
}

LightningModalTypes.ModalConfirmCardComponent.component = ModalConfirmCardComponent;

@Component({
  templateUrl: './modal-recover-password.component.html'
})
export class ModalRecoverPasswordComponent {
  expiredPassword: boolean = false;
  title = this.translator.instant('Recover Password');
  constructor(public activeModal: NgbActiveModal, public translator: TranslateService) { }
  ngOnInit() {
    if (this.expiredPassword) {
      this.title = this.translator.instant('Expired Password');
    }
  }
}
LightningModalTypes.ModalRecoverPasswordComponent.component = ModalRecoverPasswordComponent;

@Component({
  templateUrl: './modal-expired-password.component.html'
})
export class ModalExpiredPasswordComponent {
  constructor(public activeModal: NgbActiveModal) { }
}

LightningModalTypes.ModalExpiredPasswordComponent.component = ModalExpiredPasswordComponent;
@Component({
  templateUrl: './modal-card-error.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalCardErrorComponent {
  constructor(public activeModal: NgbActiveModal) { }
}
LightningModalTypes.ModalCardErrorComponent.component = ModalCardErrorComponent;

@Component({
  templateUrl: './modal-support-email.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalSupportEmailComponent {
  message: string;
  constructor(public activeModal: NgbActiveModal) { }
}
LightningModalTypes.ModalSupportEmailComponent.component = ModalSupportEmailComponent;

@Component({
  templateUrl: './modal-language-save.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalLanguageSaveComponent implements OnInit {
  Language: Language;
  emitter: EventEmitter<boolean>;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() { }

  save() {
    this.emitter.emit(true);
    this.activeModal.close(false);
  }

  onlySelect() {
    this.emitter.emit(false);
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.close(true);
  }
}
LightningModalTypes.ModalLanguageSaveComponent.component = ModalLanguageSaveComponent;
@Component({
  templateUrl: './modal-custom-error.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalCustomErrorComponent {
  errorTitle: string;
  errorMessage: string;

  constructor(public activeModal: NgbActiveModal) { }

  OK() {
    this.activeModal.close(true);
  }
}
LightningModalTypes.ModalCustomErrorComponent.component = ModalCustomErrorComponent;

@Component({
  templateUrl: './modal-screenreader-basket.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalScreenreaderBasketComponent {
  constructor(public activeModal: NgbActiveModal) { }

  continue() {
    this.activeModal.close(true);
    if (window.location.hash.includes('flights')) {
      // Skip back to top of flights as the old element will have gone
      const focusable = document
        .getElementById('flight-container-2')
        .querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
      // @ts-ignore
      focusable[0].focus();
    }
  }

  skipToBasket(event) {
    event.preventDefault();
    this.activeModal.close(true);
    setTimeout(function () {
      const focusable: NodeListOf<HTMLElement> = document
        .getElementById('basket')
        .querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
      let x = 0;
      // @ts-ignore
      while (
        // @ts-ignore
        window.getComputedStyle(focusable[x]).display === 'none' ||
        focusable[x].tabIndex === -1
      ) {
        x++;
      }
      // @ts-ignore
      focusable[x].focus();
    }, 0);
  }
}
LightningModalTypes.ModalScreenreaderBasketComponent.component = ModalScreenreaderBasketComponent;

@Component({
  templateUrl: './modal-hotel-check.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ModalHotelCheckComponent {
  constructor(public activeModal: NgbActiveModal) { }

  public nights: number = null;
  public location: string = null;
  public close = null;
  public modalContinue = null;

  accept() {
    this.activeModal.close(true);
    this.modalContinue();
  }

  reject() {
    this.activeModal.close(true);
    this.close();
  }
}

LightningModalTypes.ModalHotelCheckComponent.component = ModalHotelCheckComponent;

@Component({
  templateUrl: './modal-live-rail-legs.component.html',
  styleUrls: ['./../search-module/components/rail-live/rail-live-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalLiveRailLegsComponent {
  constructor(public activeModal: NgbActiveModal) { }
  public legs = null;

  public getTrainStatus(railItem: DepartureBoardService, stops: boolean = false): string {
    return stops
      ? moment(railItem.etd) > moment(railItem.std)
        ? 'Delayed'
        : 'On Time'
      : 'On Time';
  }
}

LightningModalTypes.ModalLiveRailLegsComponent.component = ModalLiveRailLegsComponent;

@Component({
  templateUrl: './modal-leave-itinerary.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ModalLeaveItineraryComponent {
  constructor(public activeModal: NgbActiveModal) { }

  displayItinerary = false;
}
LightningModalTypes.ModalLeaveItineraryComponent.component = ModalLeaveItineraryComponent;

@Component({
  templateUrl: './modal-lcc-markup.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalLccMarkup {
  public amount: number = 0;
  public cost: number = 0;
  constructor(public activeModal: NgbActiveModal) { }

  applyMarkup(): void {
    this.activeModal.close({ markup: { cost: this.cost, amount: this.amount } });
  }

  checkValue(value: string, type: 'amount' | 'cost') {
    if (!Number.isNaN(+value)) {
      type === 'amount' ? (this.amount = +value) : (this.cost = +value);
    }
  }
}
LightningModalTypes.ModalLccMarkup.component = ModalLccMarkup;
@Component({
  templateUrl: './modal-resend-approval-email.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ResendApprovalEmailComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) { }
  data: boolean = false;
  ngOnInit() {
    console.log(this.data);
  }
}

LightningModalTypes.ResendApprovalEmailComponent.component = ResendApprovalEmailComponent;

@Component({
  selector: 'app-auto-logout',
  templateUrl: './auto-logout.component.html'
})
export class AutoLoggingOutComponent implements OnInit, OnDestroy {
  public timeOutDuration: number = 60;
  public logout = LogoutEnum;

  constructor(private activeModal: NgbActiveModal, private logonService: LogonService) { }
  private interval = null;

  ngOnInit(): void {
    this.interval = setInterval(() => this.checkInterval(), 1000);
  }

  checkInterval() {
    this.timeOutDuration -= 1;
    if (this.timeOutDuration <= 0) {
      clearInterval(this.interval);
      this.close(LogoutEnum.LOGOUT);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  close(result: LogoutEnum) {
    if (result === LogoutEnum.LOGOUT) {
      this.logonService.logout();
    }
    this.activeModal.close(result);
  }
}

LightningModalTypes.AutoLoggingOutComponent.component = AutoLoggingOutComponent;

// @Component({
//   templateUrl: './modal-auto-logout.component.html'
// })
// export class AutoLogoutComponent {
//   constructor(public activeModal: NgbActiveModal) { }
// }

// LightningModalTypes.AutoLogoutComponent.component = AutoLogoutComponent;

@Component({
  templateUrl: './modal-wizzair-warning.component.html'
})
export class ModalWizzairWarning {
  constructor(public activeModal: NgbActiveModal) { }
}

LightningModalTypes.ModalWizzairWarning.component = ModalWizzairWarning;

@Component({
  templateUrl: './modal-delete-saved-addresses.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalDeleteSavedAddresses {
  constructor(public activeModal: NgbActiveModal) { }

  public addresses = null;
  public removeAddress = null;
  public searchService = null;
  public addressList;
  ngOnInit() {
    this.addresses.subscribe(event => {
      this.addressList = event;
    });
  }
}
LightningModalTypes.ModalDeleteSavedAddresses.component = ModalDeleteSavedAddresses;

@Component({
  templateUrl: './modal-hotel-reviews.component.html',
  styleUrls: ['./modal.component.scss'],
  selector: 'app-hotel-reviews'
})
export class ModalHotelReviewsComponent {
  constructor(public activeModal: NgbActiveModal, public iconLibrary: FaIconLibrary) {
    this.iconLibrary.addIconPacks(far);
  }

  @Input() public hotelRatingsInfo = null;
}
LightningModalTypes.ModalHotelReviewsComponent.component = ModalHotelReviewsComponent;

@Component({
  templateUrl: './modal-car-details.component.html',
  styleUrls: ['./modal.component.scss'],
  selector: 'app-car-details-modal'
})
export class ModalCarDetailsComponent {
  constructor(public activeModal: NgbActiveModal, public iconLibrary: FaIconLibrary) {
    this.iconLibrary.addIconPacks(far);
  }

  @Input() public result = null;
  @Input() public carHireAvailabilityDetailsObject = null;
  @Input() public carTypeCodes = null;

  getCo2Usage(result: CarHireVehicleAvailibility): string {
    try {
      return result.co2PerItem.toFixed(2);
    } catch (error) { }
    return '0.00';
  }

  getTypeForDisplayFromResult(result: CarHireVehicleAvailibility): string {
    const typeForDisplay = this.carTypeCodes.find(
      carTypeCode => carTypeCode[2] === result?.displayRecord?.vehicle?.type
    );
    if (typeForDisplay) {
      return typeForDisplay[1];
    }
  }
}
LightningModalTypes.ModalCarDetailsComponent.component = ModalCarDetailsComponent;


@Component({
  templateUrl: './modal-custom-warning.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalCustomWarningComponent {
  warningMessage: string = null;
  exclamationURL = 'https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/exclamation-circle-solid.svg';
  constructor(public activeModal: NgbActiveModal) { }

  OK() {
    this.activeModal.close();
  }
}
LightningModalTypes.ModalCustomWarningComponent.component = ModalCustomWarningComponent;

