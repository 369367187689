<div class="flight-extras-container">
  <div class="half-container">
    <div class="half-block">
      <ng-container *ngIf="loyaltyCode; else enterLoyaltyNumber">
        <label *ngIf="loyaltyCode">Loyalty Number</label>
      </ng-container>
      <ng-template #enterLoyaltyNumber>
        <label *ngIf="!loyaltyCode">Enter {{ operatingCarrierName }} Loyalty Number </label>
      </ng-template>
      <input
        class="input-box input-box--border input-box--no-iconed"
        type="text"
        [value]="loyaltyCode"
        (input)="updateLoyaltyCode($event)" />
    </div>
    <div class="dob_guest">
      <label i18n="@@DOB_loyalty" for="dobLoyalty">Date of Birth</label>
      <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
      <input
        autocomplete="off"
        class="input-box input-box--border combo-box"
        i18n-placeholder
        placeholder="Select date of birth"
        name="dp"
        [displayMonths]="deviceDetector.displayMonths"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [startDate]="startDate"
        [(ngModel)]="chosenDob"
        [navigation]="'select'"
        ngbDatepicker
        #dp
        container="body"
        #d="ngbDatepicker"
        (ngModelChange)="loyaltyCheckWithDob()"
        (click)="d.toggle()"
        placement="left"
        tabindex="0"
        [required]="dobMandatory"
        id="dobLoyalty" />
    </div>
    <div class="half-block">
      <button
        type="button"
        class="small-button"
        [disabled]="!recalcActive || (this.dobMandatory && !this.chosenDob)"
        id="recalculate-button"
        (click)="recalcuateSeats()">
        Recalculate
      </button>
      <i
        class="ico ico-info-circle info-button"
        i18n-ngbTooltip
        ngbTooltip="A change has been made to the Loyalty input field, click on the recalculate button to ensure your membership benefits are applied"
        attr.aria-label="A change has been made to the Loyalty input field, click on the recalculate button to ensure your membership benefits are applied"
        triggers="manual"
        #ttHint="ngbTooltip"
        (mouseenter)="ttHint.open()"
        (focus)="ttHint.open()"
        (blur)="ttHint.close()"
        tabindex="0"
        ><span class="visuallyhidden"
          >A change has been made to the Loyalty input field, click on the recalculate button to
          ensure your membership benefits are applied</span
        ></i
      >
    </div>
  </div>
</div>
