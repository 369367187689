import { Component, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import {
  User,
  WithSubscriptionComponent,
  EnterpriseBasketService
} from '@sabstravtech/obtservices/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  templateUrl: './approvers-modal.component.html',
  styleUrls: ['./approvers-modal.component.scss']
})
export class ApproversModalComponent extends WithSubscriptionComponent {
  title: string = 'Choose Approver';
  approvers: User[] = [];
  chosen_approver: User = null;
  isSelectedApprover = false;
  isSelectedMIApprover = false;
  isSelectedNotifyApprover = false;
  chosenNotifyApprover: User = null;
  basketUser: User = null;
  isWaningActive = false;
  outboundClick$: Observable<string> = new Subject();
  filteredApprovers: User[] = [];
  approvalLevel = 1;
  approversLevel = [];
  levelClick$: any;
  focus$: any[];
  filteredLevelApprovers: User[][] = [];
  constructor(
    public activeModal: NgbActiveModal,
    private basketService: EnterpriseBasketService,
    private translator: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  userFormatter = (x: User) => `${x.title} ${x.forename} ${x.surname} (${x.email})`;

  ngOnInit() {
    this.sortApprovers();
    for (let i = 0; i < this.approvalLevel; i++) {
      this.approversLevel.push({
        chosenApprover: null,
        level: i + 1
      });
    }
    this.levelClick$ = [...Array(this.approvalLevel).fill('')].map(_ => new Subject<string>());
    this.focus$ = [...Array(this.approvalLevel).fill('')].map(_ => new Subject<string>());
    this.filteredLevelApprovers = [...Array(this.approvalLevel).fill('')].map(temp =>
      _.cloneDeep(this.filteredApprovers)
    );
  }

  sortApprovers(): void {
    this.approvers.sort((a: User, b: User) => {
      const result = a.forename.localeCompare(b.forename); //Sort by first name then second name
      return result !== 0 ? result : a.surname.localeCompare(b.surname);
    });
    this.filteredApprovers = this.approvers;
  }

  searchUsers = (text$: Observable<string>): Observable<User[]> => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(this.outboundClick$, debouncedText$).pipe(
      map(
        (term: string) =>
          (this.filteredApprovers = this.approvers.filter(option =>
            option.email.toLowerCase().includes(term.toLowerCase())
          ))
      )
    );
  };

  searchUsersLevel(text$: Observable<string>, instance, index: number): any {
    return (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
      const clicksWithClosedPopup$ = this.levelClick$[index].pipe(
        filter(() => !instance.isPopupOpen())
      );
      const inputFocus$ = this.focus$[index];

      return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
        map((term: string) =>
          this.filteredLevelApprovers[index].filter(
            option =>
              option.email.toLowerCase().includes(term.toLowerCase()) ||
              `${option.forename.toLowerCase()} ${option.surname.toLowerCase()}`.includes(
                term.toLowerCase()
              )
          )
        )
      );
    };
  }

  // remove the chosen approver from all indicies greater than the one passed in
  updateApprovers(chosenApprover: any, index: number) {
    // first of all clone the approver list at the index
    const cloneApprovers = _.cloneDeep(this.filteredLevelApprovers[index]);

    for (let i = index + 1; i < this.filteredLevelApprovers.length; i++) {
      this.filteredLevelApprovers[i] = _.cloneDeep(cloneApprovers);
      // now remove the passed in approver
      this.filteredLevelApprovers[i] = this.filteredLevelApprovers[i].filter(
        approver => approver.id !== chosenApprover.item.id
      );
      this.approversLevel[i].chosenApprover = null;
    }
  }

  onClick(event: any): void {
    const searchTerm = event.target.value;
    this.filteredApprovers = this.approvers.filter(option =>
      option.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    this.cdr.markForCheck(); // Trigger change detection manually if necessary
  }

  async closeModal(): Promise<void> {
    console.log('Closing Modal...');
    const isAllowSelfApprove = this.basketUser.office.allowSelfApprove;

    if (this.approvalLevel > 1) {
      const returnData = {
        approvers: this.approversLevel
      };
      this.activeModal.close(returnData);
    } else if (isAllowSelfApprove || this.chosen_approver?.id !== this.basketUser.id) {
      const returnData = {
        selectedApprover: this.chosen_approver,
        selectedNotifyApprover: this.chosenNotifyApprover
      };
      this.activeModal.close(returnData);
    } else if (!this.isWaningActive) {
      this.isWaningActive = true;
      const message = this.translator.instant('You cannot self-approve.');
      this.isWaningActive = await this.basketService.openApproveWarning(message);
    }
  }

  getEmail(approver: User): string {
    return approver.email;
  }
}

LightningModalTypes.ApproversModalComponent.component = ApproversModalComponent;

