<section (mouseleave)="tooltip.close()" class="container">
  <a
    class="fare_rules_link"
    triggers="manual"
    [ngbTooltip]="mixedCabinTooltip"
    #tooltip="ngbTooltip"
    (mouseenter)="tooltip.open()"
    (focus)="tooltip.open()"
    (blur)="tooltip.close()"
    attr.aria-label="Mixed cabin info"
    tabindex="0"
    role="tooltip">
    <i class="currency_info_icon" aria-hidden="true"></i>
    <span i18n="@@MixedCabin">Mixed cabin</span>
  </a>
  <ng-template #mixedCabinTooltip>
    <strong>
      {{ flights[0].cabinClass | splitCamelCase }}
      <span i18n="@@flilghtIsPartiallyAvailable">is partially available</span>
    </strong>
    <div>
      {{ flights[0].cabinClass | splitCamelCase }}
      <span i18n="@@flilghtIsNotAvailable">is not available on all the sectors of journey</span>.
    </div>
    <div *ngIf="inboundFlights.length">
      <span i18n="@@returnOutbound">Outbound</span>:
    </div>
    <div *ngFor="let flight of flights">
      <strong>{{ flight.originAirport }} <span i18n="@@flilghtSearchTo">to</span> {{ flight.destinationAirport }}:</strong>
      {{ flight.cabinCode === 'Y' ? 'Economy' : flight.cabinClass | splitCamelCase}}
    </div>
    <div *ngIf="inboundFlights.length">
      <span i18n="@@returnInbound">Inbound</span>:
      <div *ngFor="let flight of inboundFlights">
        <strong>{{ flight.originAirport }} <span i18n="@@flilghtSearchTo">to</span> {{ flight.destinationAirport }}:</strong>
        {{ flight.cabinCode === 'Y' ? 'Economy' : flight.cabinClass | splitCamelCase}}
      </div>
    </div>
  </ng-template>
</section>